//

import React, { useState } from 'react';
import { Form, Input, Select, Button, Upload, message, Col, Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Container } from 'react-bootstrap';
import {
  createUpdateGiftItem,
  uploadImageToAPI,
} from '../../Services/ApiServices';

const { Option } = Select;

const AddGift = () => {
  const [form] = Form.useForm();
  const [imageUploading, setImageUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const onFinish = async (values) => {
    try {
      const response = await createUpdateGiftItem({
        name: values.name,
        amount: values.amount,
        status: values.status,
        image: selectedImage,
      });
      message.success(response.message);
      form.resetFields();
    } catch (error) {
      message.error('Failed to add gift item. Please try again.');
    }
  };

  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!');
      return Upload.LIST_IGNORE;
    }
    setImageUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await uploadImageToAPI(formData);
      if (response.success) {
        setSelectedImage(response.response[0]);
      }
    } catch (error) {
      message.error('Failed to upload image!');
    } finally {
      setImageUploading(false);
    }
    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Container>
      <div className="heading-challenge mb-2">Add Gift Item</div>
      <Form
        form={form}
        className="rounded border border-1 p-3"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ status: 'active' }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the gift name!' }]}
        >
          <Input placeholder="Enter gift name" />
        </Form.Item>

        <Form.Item
          name="amount"
          label="Amount"
          rules={[{ required: true, message: 'Please enter the amount!' }]}
        >
          <Input type="number" placeholder="Enter amount" />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select the status!' }]}
        >
          <Select placeholder="Select status">
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="image"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Please upload an image!' }]}
        >
          <Upload.Dragger
            name="image"
            beforeUpload={beforeUpload}
            maxCount={1}
            className="w-100"
            style={{ padding: '20px', border: '1px dashed #ccc' }}
            listType="picture"
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">Click or drag image to upload</p>
          </Upload.Dragger>
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Button
              type="primary"
              className="w-100"
              htmlType="submit"
              disabled={imageUploading}
              style={{
                background: '#dc0272',
                color: 'white',
                fontWeight: '600',
              }}
            >
              Add Gift
            </Button>
          </Col>
          <Col span={12}>
            <Button
              htmlType="button"
              onClick={() => form.resetFields()}
              className="w-100"
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddGift;
