import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const MapChallenge = ({ google, handleLocationSelect, initialLocation }) => {
  const [mapCenter, setMapCenter] = useState(initialLocation || null);
  const [markers, setMarkers] = useState(
    initialLocation ? [{ position: initialLocation }] : []
  );
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setMapCenter({
  //           lat: latitude,
  //           lng: longitude,
  //         });
  //       },
  //       (error) => {
  //         console.error('Error fetching geolocation:', error);
  //         setError(error.message);
  //       }
  //     );
  //   } else {
  //     console.error('Geolocation is not supported by this browser.');
  //     setError('Geolocation is not supported by this browser.');
  //   }
  // }, []);

  useEffect(() => {
    if (!initialLocation && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({
            lat: latitude,
            lng: longitude,
          });
        },
        (error) => {
          console.error('Error fetching geolocation:', error);
          setError(error.message);
        }
      );
    } else if (initialLocation) {
      setMapCenter(initialLocation);
    }
  }, [initialLocation]);

  const handleMapClick = (mapProps, map, clickEvent) => {
    const lat = clickEvent.latLng.lat();
    const lng = clickEvent.latLng.lng();
    setMarkers([{ position: { lat, lng } }]);
    handleLocationSelect(lat, lng);
  };

  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '300px',
  };

  return (
    <div className="map-container">
      {error ? (
        <p>Error fetching geolocation: {error}</p>
      ) : mapCenter ? (
        <Map
          google={google}
          initialCenter={mapCenter}
          style={mapStyles}
          zoom={14}
          containerStyle={containerStyle}
          className={'map'}
          onClick={handleMapClick}
        >
          {markers.map((marker, index) => (
            <Marker key={index} position={marker.position} />
          ))}
        </Map>
      ) : (
        <p>Waiting for location permission...</p>
      )}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAwrmb9xgfWFEoj15Rwek4lDU_boPz9EO8',
})(MapChallenge);
