import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  fetchPrivacyPolicy,
  updatePrivacyPolicy,
} from '../../Services/ApiServices';
import { toast } from 'react-toastify';
import './PrivacyPolicy.css';
import Loader from '../Loader/Loader';

const PrivacyPolicy = () => {
  const [value, setValue] = useState('');
  const [policyId, setPolicyId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPolicy = async () => {
      setLoading(true);
      try {
        const policyData = await fetchPrivacyPolicy();
        setValue(policyData.privacy_policy);
        setPolicyId(policyData._id);
      } catch (error) {
        toast.error('Failed to fetch privacy policy');
      } finally {
        setLoading(false);
      }
    };

    fetchPolicy();
  }, []);

  const handleUpdatePrivacyPolicy = async () => {
    setLoading(true);
    try {
      await updatePrivacyPolicy({
        privacy_policy: value,
        privacyId: policyId,
      });
      toast.success('Privacy policy updated');
      const updatedPolicyData = await fetchPrivacyPolicy();
      setValue(updatedPolicyData.privacy_policy);
      setPolicyId(updatedPolicyData._id);
    } catch (error) {
      toast.error('Failed to update privacy policy');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-3">
      <h3 className="privacyPolicy">Privacy Policy</h3>

      {loading ? (
        <Loader />
      ) : (
        <>
          <ReactQuill
            className="mb-3"
            value={value}
            onChange={setValue}
            placeholder="Write your privacy policy here..."
            modules={{
              toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                ['link'],
                ['clean'],
              ],
            }}
            formats={[
              'header',
              'font',
              'size',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'video',
            ]}
          />
          <div className="float-end mt-4">
            <button className="btn btn-bg" onClick={handleUpdatePrivacyPolicy}>
              Update Privacy Policy
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PrivacyPolicy;
