import React, { useState } from 'react';
import { Col, Form, Row, Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateTime from 'react-datetime';
import moment from 'moment';
import { createPhysicalMarathon } from '../../Services/ApiServices';
import Loader from '../Loader/Loader';

const PhysicalMarathon = () => {
  const [formData, setFormData] = useState({
    marathon_title: '',
    startDate: '',
    endDate: '',
    startPointAddress: '',
    endPointAddress: '',
    age: '',
    gender: '',
    contact: '',
    marathonCategory: '',
    pickupItems: [{ itemName: '', quantity: '' }],
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (index >= 0) {
      const updatedPickupItems = formData.pickupItems.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      );
      setFormData({ ...formData, pickupItems: updatedPickupItems });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleDateTimeChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    if (name === 'startDate') {
      if (moment(formData.endDate).isBefore(value)) {
        setFormData({ ...formData, endDate: '' });
      }
    }
  };

  const handleAddItem = () => {
    setFormData({
      ...formData,
      pickupItems: [...formData.pickupItems, { itemName: '', quantity: '' }],
    });
  };

  const handleRemoveItem = (index) => {
    if (formData.pickupItems.length > 1) {
      const updatedItems = formData.pickupItems.filter((_, i) => i !== index);
      setFormData({ ...formData, pickupItems: updatedItems });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const phoneNumberRegex = /^[0-9]*$/;

    if (!formData.marathon_title)
      newErrors.marathon_title = 'Marathon Title is required';
    if (!formData.startDate) newErrors.startDate = 'Start date is required';
    if (!formData.endDate) newErrors.endDate = 'End date is required';
    if (!formData.startPointAddress)
      newErrors.startPointAddress = 'Starting point address is required';
    if (!formData.endPointAddress)
      newErrors.endPointAddress = 'Ending point address is required';
    if (!formData.age) newErrors.age = 'Age is required';
    if (!formData.gender) newErrors.gender = 'Gender is required';
    if (!formData.contact) {
      newErrors.contact = 'Contact number is required';
    } else if (!phoneNumberRegex.test(formData.contact)) {
      newErrors.contact = 'Contact number should contain only numbers';
    }
    if (!formData.marathonCategory)
      newErrors.marathonCategory = 'Marathon category is required';

    formData.pickupItems.forEach((item, index) => {
      if (!item.itemName)
        newErrors[`itemName${index}`] = 'Item name is required';
      if (!item.quantity)
        newErrors[`quantity${index}`] = 'Quantity is required';
    });

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setLoading(true);
        const response = await createPhysicalMarathon(formData);

        if (response.status === 200) {
          toast.success(response.message);
          setFormData({
            marathon_title: '',
            startDate: '',
            endDate: '',
            startPointAddress: '',
            endPointAddress: '',
            age: '',
            gender: '',
            contact: '',
            marathonCategory: '',
            pickupItems: [{ itemName: '', quantity: '' }],
          });
          setErrors({});
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Failed to register marathon. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
  };

  const isValidDate = (current, selectedDate) => {
    const yesterday = moment().subtract(1, 'day');
    return current.isAfter(yesterday) && current.isSameOrAfter(selectedDate);
  };

  return (
    <div className="form-container container">
      {loading && <Loader />}
      <h2 className="mb-4 heading-challenge">Physical Marathon</h2>
      <Card className="mt-2 mx-auto p-4 bg-light">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Marathon Title <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="marathon_title"
                  placeholder="Enter marathon title"
                  value={formData.marathon_title}
                  onChange={handleChange}
                  isInvalid={!!errors.marathon_title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.marathon_title}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Start Date <span className="text-danger">*</span>
                </Form.Label>
                <DateTime
                  value={formData.startDate}
                  onChange={(value) => handleDateTimeChange('startDate', value)}
                  inputProps={{
                    placeholder: 'Enter start date and time',
                  }}
                  onBlur={() => {
                    if (!formData.startDate) {
                      setErrors({
                        ...errors,
                        startDate: 'Start date is required',
                      });
                    }
                  }}
                  isValidDate={(current) => isValidDate(current)}
                  closeOnSelect
                  dateFormat="YYYY-MM-DD"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.startDate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  End Date <span className="text-danger">*</span>
                </Form.Label>
                <DateTime
                  value={formData.endDate}
                  onChange={(value) => handleDateTimeChange('endDate', value)}
                  inputProps={{
                    placeholder: 'Enter end date and time',
                  }}
                  onBlur={() => {
                    if (!formData.endDate) {
                      setErrors({
                        ...errors,
                        endDate: 'End date is required',
                      });
                    }
                  }}
                  isValidDate={(current) =>
                    isValidDate(current, formData.startDate)
                  }
                  closeOnSelect
                  dateFormat="YYYY-MM-DD"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.endDate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Starting Point Address <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="startPointAddress"
                  placeholder="Enter start point address"
                  value={formData.startPointAddress}
                  onChange={handleChange}
                  isInvalid={!!errors.startPointAddress}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.startPointAddress}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Ending Point Address <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="endPointAddress"
                  placeholder="Enter end point address"
                  value={formData.endPointAddress}
                  onChange={handleChange}
                  isInvalid={!!errors.endPointAddress}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.endPointAddress}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Card className="p-3 my-3">
            <h3>Pickup Items:</h3>
            {formData.pickupItems.map((item, index) => (
              <Row key={index} className="mt-2 p-2">
                <Col md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Item Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="itemName"
                      placeholder="Enter item name"
                      value={item.itemName}
                      onChange={(e) => handleChange(e, index)}
                      isInvalid={!!errors[`itemName${index}`]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors[`itemName${index}`]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Quantity <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="quantity"
                      placeholder="Enter quantity"
                      value={item.quantity}
                      onChange={(e) => handleChange(e, index)}
                      isInvalid={!!errors[`quantity${index}`]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors[`quantity${index}`]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Row className="mb-3">
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={handleAddItem}
                      className="me-2"
                    >
                      + Add Item
                    </Button>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveItem(index)}
                      disabled={formData.pickupItems.length === 1}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              </Row>
            ))}
          </Card>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Age Limit <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="age"
                  placeholder="Enter age limit"
                  value={formData.age}
                  onChange={handleChange}
                  isInvalid={!!errors.age}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.age}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Gender <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  isInvalid={!!errors.gender}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Open</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.gender}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Contact Number <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="contact"
                  placeholder="Enter contact number"
                  value={formData.contact}
                  onChange={handleChange}
                  isInvalid={!!errors.contact}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contact}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Marathon Category <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="marathonCategory"
                  placeholder="Enter marathon category"
                  value={formData.marathonCategory}
                  onChange={handleChange}
                  isInvalid={!!errors.marathonCategory}
                >
                  <option value="">Select Category</option>
                  <option value="5k">5K</option>
                  <option value="10k">10K</option>
                  <option value="half-marathon">Half Marathon</option>
                  <option value="full-marathon">Full Marathon</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.marathonCategory}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <Button
              variant="success"
              className="col-6 p-2 fs-6 fw-bold border-0"
              type="submit"
              style={{ background: '#dc0272' }}
            >
              Register
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default PhysicalMarathon;
