import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Button, Space } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import {
  createOrUpdateChallenge,
  fetchChallengeList,
  formatDateTime,
} from '../../Services/ApiServices';
import ChallengeDetailsUpdate from '../ChallengeDetailsUpdate/ChallengeDetailsUpdate';
import ChallengeDetailsModal from './ChallengeDetailsModal';
import Loader from '../Loader/Loader';
import './ChallengeList.css';

const { Search } = Input;
const { Option } = Select;

const ChallengeList = () => {
  const [challenges, setChallenges] = useState([]);
  const [filteredChallenges, setFilteredChallenges] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [detailVisible, setDetailsVisible] = useState(false);
  const [selectedChallengeId, setSelectedChallengeId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCount, setFilteredCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchChallengeList();
        if (data && data.response) {
          setChallenges(data.response);
          setFilteredChallenges(data.response);
          setFilteredCount(data.response.length);
        }
      } catch (error) {
        console.error('Failed to fetch challenges:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (value) => {
    const filteredData = challenges.filter((challenge) =>
      challenge.challenge_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredChallenges(filteredData);
    setFilteredCount(filteredData.length);
  };

  const handleMenuClick = (record, action) => {
    setSelectedChallengeId(record._id);
    if (action.key === 'edit') {
      setModalVisible(true);
    } else if (action.key === 'view') {
      setDetailsVisible(true);
    }
  };

  // const handleStatusChange = async (value, challenge) => {
  //   try {
  //     setLoading(true);
  //     await createOrUpdateChallenge({
  //       ...challenge,
  //       status: value === 'active' ? 1 : 2,
  //     });
  //     const updatedChallenges = challenges.map((c) =>
  //       c._id === challenge._id
  //         ? { ...c, status: value === 'active' ? 1 : 2 }
  //         : c
  //     );
  //     setChallenges(updatedChallenges);
  //     setFilteredChallenges(updatedChallenges);
  //   } catch (error) {
  //     console.error('Error updating challenge status:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleStatusChange = async (value, challenge) => {
    try {
      setLoading(true);
      const { challengeQrUrl, ...updatedChallenge } = challenge;
      const updatedChallengeData = {
        ...updatedChallenge,
        status: value === 'active' ? 1 : 2,
      };

      console.log('updatedChallengeData: ', updatedChallengeData);
      await createOrUpdateChallenge(updatedChallengeData);

      const updatedChallenges = challenges.map((c) =>
        c._id === challenge._id
          ? { ...c, status: value === 'active' ? 1 : 2 }
          : c
      );
      setChallenges(updatedChallenges);
      setFilteredChallenges(updatedChallenges);
    } catch (error) {
      console.error('Error updating challenge status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const handleStatusFilterChange = (value) => {
    setSelectedStatus(value);
    const filteredData =
      value === 'all'
        ? challenges
        : challenges.filter((c) =>
            value === 'active' ? c.status === 1 : c.status === 2
          );
    setFilteredChallenges(filteredData);
    setFilteredCount(filteredData.length);
  };

  const columns = [
    {
      title: 'S.No.',
      key: 'index',
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
      align: 'center',
    },
    {
      title: 'Challenge Image',
      dataIndex: 'challenge_image',
      key: 'challenge_image',
      render: (challenge_image) =>
        challenge_image && challenge_image.length > 0 ? (
          <img
            src={challenge_image[0]}
            alt="Challenge"
            style={{ width: 100, height: 'auto', borderRadius: '10px' }}
          />
        ) : (
          '-'
        ),
      align: 'center',
    },
    {
      title: 'Challenge Name',
      dataIndex: 'challenge_name',
      key: 'challenge_name',
      align: 'center',
    },
    {
      title: 'Type',
      dataIndex: 'challenge_type',
      key: 'challenge_type',
      align: 'center',
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
      render: formatDateTime,
      align: 'center',
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
      render: formatDateTime,
      align: 'center',
    },
    {
      title: 'Time Limit',
      dataIndex: 'time_limit',
      key: 'time_limit',
      align: 'center',
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      align: 'center',
    },
    {
      title: 'Original Price',
      dataIndex: 'original_price',
      key: 'original_price',
      align: 'center',
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status === 1 ? 'active' : 'inactive'}
          style={{ width: 120 }}
          onChange={(value) => handleStatusChange(value, record)}
        >
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      ),
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="btn btn-none"
            onClick={() => handleMenuClick(record, { key: 'view' })}
            icon={<EyeOutlined className="text-dark" />}
          />
          <Button
            className="btn btn-none"
            onClick={() => handleMenuClick(record, { key: 'edit' })}
            icon={<EditOutlined className="text-dark" />}
          />
        </Space>
      ),
      align: 'center',
    },
  ];

  return (
    <div className="table-responsive">
      {loading && <Loader spinning={true} />}
      <div className="d-flex justify-content-between mb-3">
        <div className="heading-challenge">Challenge List</div>
        <div className="text-center align-self-center count-text">
          Total Challenges: {filteredCount}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 col-md-10 col-sm-12">
          {' '}
          <Search
            placeholder="Search by challenge name"
            allowClear
            enterButton={
              <Button style={{ backgroundColor: '#dc0272', color: '#fff' }}>
                Search
              </Button>
            }
            onChange={(e) => handleSearch(e.target.value)}
            className="mb-2"
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12">
          <Select
            value={selectedStatus}
            className="w-100"
            onChange={handleStatusFilterChange}
          >
            <Option value="all">All</Option>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        </div>
      </div>
      <Table
        style={{ width: '100%', tableLayout: 'fixed' }}
        columns={columns}
        dataSource={filteredChallenges}
        pagination={{ pageSize: 20 }}
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange}
      />
      <ChallengeDetailsUpdate
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        challenge_id={selectedChallengeId}
      />
      <ChallengeDetailsModal
        visible={detailVisible}
        onClose={() => setDetailsVisible(false)}
        challenge_id={selectedChallengeId}
      />
    </div>
  );
};

export default ChallengeList;
