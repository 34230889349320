import React, { useState, useEffect } from 'react';
import { Modal, Col, Row, Tabs, Image, Empty } from 'antd';
import { fetchMarathonDetails } from '../../Services/ApiServices';
import Loader from '../Loader/Loader';
import './PhysicalMarathon.css';
import UserCard from './UserCards';
import moment from 'moment';

const { TabPane } = Tabs;

const formatDate = (date) => {
  if (!date) return '';
  return moment(date).format('MMMM Do YYYY, h:mm:ss a');
};

const PhsicalMarathonDetailsShow = ({ marathon_id, onClose, visible }) => {
  const [marathonDetails, setMarathonDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchMarathon = async () => {
    setLoading(true);
    try {
      const response = await fetchMarathonDetails(marathon_id);
      setMarathonDetails(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible && marathon_id) {
      fetchMarathon();
    }
  }, [marathon_id, visible]);

  const handleUserDeleted = () => {
    fetchMarathon();
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={600}
      destroyOnClose
      bodyStyle={{
        maxHeight: 'calc(100vh - 120px)',
        overflow: 'auto',
        padding: '5px',
      }}
    >
      <Row>
        <Col span={24} className="text-center">
          <h3 style={{ color: '#dc0272' }}>
            {marathonDetails?.marathon_title}
          </h3>
        </Col>
      </Row>
      <Tabs defaultActiveKey="profile" className="p-2">
        <TabPane tab="Profile" key="profile">
          {loading ? (
            <Loader size="large" />
          ) : (
            <DetailRows marathonDetails={marathonDetails} />
          )}
        </TabPane>
        <TabPane tab="Enrolled Users" key="enrollUsers">
          {loading ? (
            <Loader size="large" />
          ) : (
            <UserList
              users={marathonDetails?.enrollUsers}
              marathonId={marathon_id}
              onUserDeleted={handleUserDeleted}
            />
          )}
        </TabPane>
        <TabPane tab="Pickup Items" key="pickupItems">
          {loading ? (
            <Loader size="large" />
          ) : (
            <ul className="list-group list-group-flush">
              {marathonDetails?.pickupItems.map((item, index) => (
                <li
                  key={index}
                  className="list-group-item mb-2 p-3 border rounded-3 "
                >
                  {item.itemName} - Quantity: {item.quantity}
                </li>
              ))}
            </ul>
          )}
        </TabPane>
      </Tabs>
    </Modal>
  );
};

const DetailRows = ({ marathonDetails }) => (
  <Row>
    <DetailRow
      label="Start Date"
      value={formatDate(marathonDetails?.startDate)}
    />
    <DetailRow label="End Date" value={formatDate(marathonDetails?.endDate)} />
    <DetailRow
      label="Start Point Address"
      value={marathonDetails?.startPointAddress}
    />
    <DetailRow
      label="End Point Address"
      value={marathonDetails?.endPointAddress}
    />
    <DetailRow label="Age" value={marathonDetails?.age} />
    <DetailRow label={'Gender'} value={marathonDetails?.gender} />
    <DetailRow label="Contact" value={marathonDetails?.contact} />
    <DetailRow
      label="Marathon Category"
      value={marathonDetails?.marathonCategory}
    />
    {marathonDetails?.marathonQrCodeUrl && (
      <DetailRow
        label="Marathon QR Code"
        value={
          <Image
            src={marathonDetails.marathonQrCodeUrl}
            alt="Marathon QR Code"
            style={{ maxWidth: '100%', height: '40px' }}
          />
        }
      />
    )}
    <DetailRow label={'Marathon Code'} value={marathonDetails?.marathonCode} />
  </Row>
);

const DetailRow = ({ label, value }) => (
  <Col span={24}>
    <Row className="p-2">
      <Col span={12} style={{ fontWeight: 'bold' }}>
        {label}:
      </Col>
      <Col span={12}>{value}</Col>
    </Row>
  </Col>
);

const UserList = ({ users, marathonId, onUserDeleted }) => (
  <ul>
    {users.length === 0 ? (
      <Empty
        description="No enrolled user found"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: 60 }}
      />
    ) : (
      users.map((user, index) => (
        <UserCard
          key={index}
          user={user}
          marathonId={marathonId}
          onUserDeleted={onUserDeleted}
        />
      ))
    )}
  </ul>
);

export default PhsicalMarathonDetailsShow;
