import React, { useState, useEffect } from 'react';
import { Button, Layout, Menu, theme } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import Users from '../users/Users';
import AddChallenges from '../challenges/AddChallenges';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import ImageLogo from '../../Assets/AuthImages/marathon-logo.png';
import './AppMenu.css';
import ChallengeList from '../ChallengeList/ChallengeList';
import Support from '../Support/Support';
import PhysicalMarathon from '../PhysicalMarathon/PhysicalMarathon';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import TermsConditions from '../Terms&Conditions/Terms&Conditions';
import PhysicalMArathonList from '../PhysicalMarathon/PhysicalMArathonList';
import { FaPersonRunning } from 'react-icons/fa6';
import { MdSupportAgent } from 'react-icons/md';
import { FaUserAlt } from 'react-icons/fa';
import { MdPrivacyTip } from 'react-icons/md';
import { FaTasks } from 'react-icons/fa';
import { FaPowerOff } from 'react-icons/fa';
import { MdOutlineTaskAlt } from 'react-icons/md';
import GiftedItem from '../GiftedItem/GiftedItem';
import { BsGift } from 'react-icons/bs';
import AddGift from '../GiftedItem/AddGift';
import AdvertisementPost from '../Advertisement/AdvertisementPost';
import { RiAdvertisementLine } from 'react-icons/ri';

const { Header, Content, Sider } = Layout;

const items = [
  {
    key: '1',
    icon: <FaUserAlt />,
    label: 'Users',
    content: <Users />,
  },
  {
    key: 'challenges',
    icon: <FaTasks />,
    label: 'Challenges',
    children: [
      {
        key: '2',
        label: 'Add Challenges',
        content: <AddChallenges />,
      },
      {
        key: '3',
        label: 'Challenge List',
        content: <ChallengeList />,
      },
    ],
  },
  {
    key: '4',
    icon: <MdPrivacyTip className="fs-6" />,
    label: 'Privacy Policy',
    content: <PrivacyPolicy />,
  },
  {
    key: '5',
    icon: <MdOutlineTaskAlt className="fs-6" />,
    label: 'Terms & Conditions',
    content: <TermsConditions />,
  },
  // {
  //   key: '6',
  //   icon: <MdSupportAgent className="fs-5" />,
  //   label: 'Support',
  //   content: <Support />,
  // },
  {
    key: 'marathon',
    icon: <FaPersonRunning className="fs-5" />,
    label: 'Physical Marathon',
    children: [
      {
        key: '6',
        label: 'Add Marathon',
        content: <PhysicalMarathon />,
      },
      {
        key: '7',
        label: 'Marathon List',
        content: <PhysicalMArathonList />,
      },
    ],
  },
  {
    key: 'gift',
    icon: <BsGift className="fs-5" />,
    label: 'Gifted Item',
    children: [
      {
        key: '8',
        label: 'Add Gift',
        content: <AddGift />,
      },
      {
        key: '9',
        label: 'Gift List',
        content: <GiftedItem />,
      },
    ],
  },
  {
    key: '10',
    icon: <RiAdvertisementLine className="fs-5" />,
    label: 'Advertisement',
    content: <AdvertisementPost />,
  },
];

const NavbarSupAdmin = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [selectedItemKey, setSelectedItemKey] = useState('1');
  const [selectedContent, setSelectedContent] = useState(items[0].content);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuItemClick = (key, content) => {
    setSelectedItemKey(key);
    setSelectedContent(content);
  };

  const handleLogout = () => {
    Cookies.remove('token');
    Cookies.remove('user_details');
    toast.success('Logout Successfully');
    navigate('/');
  };

  useEffect(() => {
    const selectedItem = items
      .flatMap((item) => item.children || [item])
      .find((child) => child.key === selectedItemKey);
    if (selectedItem) {
      setSelectedContent(selectedItem.content);
    }
  }, [selectedItemKey]);

  const menuItems = items.map((item) => {
    if (item.children) {
      return {
        key: item.key,
        icon: item.icon,
        label: item.label,
        children: item.children.map((child) => ({
          key: child.key,
          label: child.label,
          onClick: () => handleMenuItemClick(child.key, child.content),
        })),
      };
    }
    return {
      key: item.key,
      icon: item.icon,
      label: item.label,
      onClick: () => handleMenuItemClick(item.key, item.content),
    };
  });

  return (
    <Layout style={{ height: '100%' }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {}}
      >
        <div className="demo-logo-vertical">
          <img
            src={ImageLogo}
            width={80}
            className="mt-2"
            style={{
              margin: 'auto',
              display: 'block',
              padding: '10px',
              background: 'none',
              borderRadius: '55%',
              backgroundColor: 'white',
              marginBottom: '15px',
            }}
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[selectedItemKey]}
          selectedKeys={[selectedItemKey]}
          items={menuItems}
        />
        <div className="p-2 mt-5">
          <Button
            danger
            style={{ backgroundColor: '#b23234' }}
            type="primary fw-bold"
            className="w-100"
            shape="round"
            onClick={handleLogout}
            icon={<FaPowerOff className="text-light" />}
          >
            Logout
          </Button>
        </div>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: '#001529',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '25px',
            alignItems: 'center',
          }}
        >
          <Button
            title="LogOut"
            className="header-logout"
            style={{ backgroundColor: '#b23234' }}
            type="primary fw-bold"
            danger
            icon={<FaPowerOff className="text-light fs-6" />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Header>
        <Content
          style={{
            margin: '20px 5px 0',
          }}
        >
          <div>{selectedContent}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default NavbarSupAdmin;

// import React, { useState, useEffect } from 'react';
// import { Button, Layout, Menu } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import Users from '../users/Users';
// import AddChallenges from '../challenges/AddChallenges';
// import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
// import ImageLogo from '../../Assets/AuthImages/marathon-logo.png';
// import './AppMenu.css';
// import ChallengeList from '../ChallengeList/ChallengeList';
// import Support from '../Support/Support';
// import PhysicalMarathon from '../PhysicalMarathon/PhysicalMarathon';
// import { toast } from 'react-toastify';
// import Cookies from 'js-cookie';
// import TermsConditions from '../Terms&Conditions/Terms&Conditions';
// import PhysicalMArathonList from '../PhysicalMarathon/PhysicalMArathonList';
// import { FaPersonRunning } from 'react-icons/fa6';
// import { MdSupportAgent } from 'react-icons/md';
// import { FaUserAlt } from 'react-icons/fa';
// import { MdPrivacyTip } from 'react-icons/md';
// import { FaTasks } from 'react-icons/fa';
// import { FaPowerOff } from 'react-icons/fa';
// import { MdOutlineTaskAlt } from 'react-icons/md';
// import GiftedItem from '../GiftedItem/GiftedItem';
// import { BsGift } from 'react-icons/bs';
// import AddGift from '../GiftedItem/AddGift';
// import AdvertisementPost from '../Advertisement/AdvertisementPost';
// import { RiAdvertisementLine } from 'react-icons/ri';
// import { RiMenu3Line } from 'react-icons/ri';

// const { Header, Content, Sider } = Layout;

// const items = [
//   {
//     key: '1',
//     icon: <FaUserAlt />,
//     label: 'Users',
//     content: <Users />,
//   },
//   {
//     key: 'challenges',
//     icon: <FaTasks />,
//     label: 'Challenges',
//     children: [
//       {
//         key: '2',
//         label: 'Add Challenges',
//         content: <AddChallenges />,
//       },
//       {
//         key: '3',
//         label: 'Challenge List',
//         content: <ChallengeList />,
//       },
//     ],
//   },
//   {
//     key: '4',
//     icon: <MdPrivacyTip className="fs-6" />,
//     label: 'Privacy Policy',
//     content: <PrivacyPolicy />,
//   },
//   {
//     key: '5',
//     icon: <MdOutlineTaskAlt className="fs-6" />,
//     label: 'Terms & Conditions',
//     content: <TermsConditions />,
//   },
//   {
//     key: '6',
//     icon: <MdSupportAgent className="fs-5" />,
//     label: 'Support',
//     content: <Support />,
//   },
//   {
//     key: 'marathon',
//     icon: <FaPersonRunning className="fs-5" />,
//     label: 'Physical Marathon',
//     children: [
//       {
//         key: '7',
//         label: 'Add Marathon',
//         content: <PhysicalMarathon />,
//       },
//       {
//         key: '8',
//         label: 'Marathon List',
//         content: <PhysicalMArathonList />,
//       },
//     ],
//   },
//   {
//     key: 'gift',
//     icon: <BsGift className="fs-5" />,
//     label: 'Gifted Item',
//     children: [
//       {
//         key: '9',
//         label: 'Add Gift',
//         content: <AddGift />,
//       },
//       {
//         key: '10',
//         label: 'Gift List',
//         content: <GiftedItem />,
//       },
//     ],
//   },
//   {
//     key: '11',
//     icon: <RiAdvertisementLine className="fs-5" />,
//     label: 'Advertisement',
//     content: <AdvertisementPost />,
//   },
// ];

// const NavbarSupAdmin = () => {
//   const [selectedItemKey, setSelectedItemKey] = useState('1');
//   const [selectedContent, setSelectedContent] = useState(items[0].content);
//   const [collapsed, setCollapsed] = useState(false);

//   const navigate = useNavigate();

//   const handleMenuItemClick = (key, content) => {
//     setSelectedItemKey(key);
//     setSelectedContent(content);
//   };

//   const handleLogout = () => {
//     Cookies.remove('token');
//     Cookies.remove('user_details');
//     toast.success('Logout Successfully');
//     navigate('/');
//   };

//   useEffect(() => {
//     const selectedItem = items
//       .flatMap((item) => item.children || [item])
//       .find((child) => child.key === selectedItemKey);
//     if (selectedItem) {
//       setSelectedContent(selectedItem.content);
//     }
//   }, [selectedItemKey]);

//   const menuItems = items.map((item) => {
//     if (item.children) {
//       return {
//         key: item.key,
//         icon: item.icon,
//         label: item.label,
//         children: item.children.map((child) => ({
//           key: child.key,
//           label: child.label,
//           onClick: () => handleMenuItemClick(child.key, child.content),
//         })),
//       };
//     }
//     return {
//       key: item.key,
//       icon: item.icon,
//       label: item.label,
//       onClick: () => handleMenuItemClick(item.key, item.content),
//     };
//   });

//   return (
//     <Layout className="layout-appmenu">
//       <Sider
//         className="sider"
//         breakpoint="lg"
//         collapsedWidth="0"
//         collapsible
//         collapsed={collapsed}
//         onCollapse={() => setCollapsed(!collapsed)}
//       >
//         <div className="demo-logo-vertical">
//           <img
//             alt="LOGO"
//             src={ImageLogo}
//             width={80}
//             className="mt-2 m-auto d-block p-2 bg-none rounded-circle bg-white"
//           />
//         </div>
//         <Menu
//           theme="dark"
//           mode="inline"
//           className="fs-6"
//           defaultSelectedKeys={[selectedItemKey]}
//           selectedKeys={[selectedItemKey]}
//           items={menuItems}
//         />
//         <div className="p-2 mt-5">
//           <Button
//             danger
//             style={{ backgroundColor: '#b23234' }}
//             type="primary fw-bold"
//             className="w-100"
//             shape="round"
//             onClick={handleLogout}
//             icon={<FaPowerOff className="text-light" />}
//           >
//             Logout
//           </Button>
//         </div>
//       </Sider>
//       <Layout>
//         {/* <Header
//           className="p-0 d-flex justify-content-end  align-item-center"
//           style={{
//             // padding: 0,
//             background: '#001529',
//             // display: 'flex',
//             // justifyContent: 'flex-end',
//             paddingRight: '25px',
//             // alignItems: 'center',
//           }}
//         >
//           <Button
//             title="LogOut"
//             style={{ backgroundColor: '#b23234' }}
//             type="primary fw-bold"
//             danger
//             icon={<FaPowerOff className="text-light fs-6" />}
//             onClick={handleLogout}
//           >
//             Logout
//           </Button>
//         </Header> */}
//         <Header className="p-0 " style={{ background: '#001529' }}>
//           <div className="d-flex justify-content-between">
//             <div>
//               <Button
//                 className="menu-toggle"
//                 type="text"
//                 classNames="float-start"
//                 style={{
//                   fontSize: '16px',
//                   width: 64,
//                   height: 64,
//                 }}
//                 onClick={() => setCollapsed(!collapsed)}
//                 icon={
//                   collapsed ? (
//                     <RiMenu3Line className="text-white" />
//                   ) : (
//                     <RiMenu3Line className="text-white" />
//                   )
//                 }
//               />
//             </div>
//             <div className="mx-2">
//               <Button
//                 title="LogOut"
//                 type="primary"
//                 danger
//                 onClick={handleLogout}
//                 icon={<FaPowerOff />}
//               >
//                 Logout
//               </Button>
//             </div>
//           </div>
//         </Header>
//         <Content className="content">
//           <div>{selectedContent}</div>
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };

// export default NavbarSupAdmin;
