import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Button, Space } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import Loader from '../Loader/Loader';
import {
  fetchMarathonsList,
  createPhysicalMarathon,
} from '../../Services/ApiServices';
import PhsicalMarathonDetailsShow from './PhsicalMarathonDetailsShow';
import PhysicalMarathonDetailsUpdateModal from './PhysicalMarathonDetailsUpdateModal';

const { Search } = Input;
const { Option } = Select;

const PhysicalMArathonList = () => {
  const [marathons, setMarathons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [searchText, setSearchText] = useState('');
  const [filteredMarathons, setFilteredMarathons] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [detailVisible, setDetailsVisible] = useState(false);
  const [selectedMarathonId, setSelectedMarathonId] = useState(null);

  useEffect(() => {
    fetchMarathons();
  }, []);

  const fetchMarathons = async () => {
    setLoading(true);
    try {
      const response = await fetchMarathonsList();
      setMarathons(response);
      setFilteredMarathons(response);
    } catch (error) {
      console.error('Error fetching marathons:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusFilterChange = (value) => {
    setSelectedStatus(value);
    filterMarathons(value, searchText);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    filterMarathons(selectedStatus, value);
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debouncedSearch = debounce(handleSearch, 300);

  const filterMarathons = (status, searchText) => {
    let filteredData = marathons;

    if (status !== 'all') {
      filteredData = filteredData.filter(
        (marathon) => marathon.status === (status === 'active' ? '1' : '2')
      );
    }

    if (searchText) {
      const lowercasedFilter = searchText.toLowerCase();
      filteredData = filteredData.filter((marathon) =>
        marathon.marathon_title.toLowerCase().includes(lowercasedFilter)
      );
    }

    setFilteredMarathons(filteredData);
  };

  const handleMenuClick = (record, action) => {
    const marathon_id = record._id;
    if (action.key === 'view') {
      setSelectedMarathonId(marathon_id);
      setModalVisible(true);
    } else if (action.key === 'edit') {
      setSelectedMarathonId(marathon_id);
      setDetailsVisible(true);
    }
  };

  const handleStatusChange = async (value, marathon) => {
    try {
      setLoading(true);
      const formData = {
        ...marathon,
        status: value === 'active' ? '1' : '2',
      };
      await createPhysicalMarathon(formData);
      const updatedMarathons = marathons.map((m) =>
        m._id === marathon._id
          ? {
              ...m,
              status: formData.status,
            }
          : m
      );
      setMarathons(updatedMarathons);
      setFilteredMarathons(updatedMarathons);
    } catch (error) {
      console.error('Error updating marathon status:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'S.No.',
      dataIndex: '_id',
      key: '_id',
      render: (text, record, index) => index + 1,
      align: 'center',
    },
    {
      title: 'Marathon Title',
      dataIndex: 'marathon_title',
      key: 'marathon_title',
      align: 'center',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate) =>
        moment(startDate, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm'),
      align: 'center',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate) =>
        moment(endDate, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm'),
      align: 'center',
    },
    {
      title: 'Start Point Address',
      dataIndex: 'startPointAddress',
      key: 'startPointAddress',
      align: 'center',
    },
    {
      title: 'End Point Address',
      dataIndex: 'endPointAddress',
      key: 'endPointAddress',
      align: 'center',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      align: 'center',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      render: (gender) =>
        gender === '2' ? 'Other' : gender === '1' ? 'Male' : 'Female',
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
      align: 'center',
    },
    {
      title: 'Marathon Category',
      dataIndex: 'marathonCategory',
      key: 'marathonCategory',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status === '1' ? 'active' : 'inactive'}
          style={{ width: 120 }}
          onChange={(value) => handleStatusChange(value, record)}
        >
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      ),
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="btn btn-none"
            onClick={() => handleMenuClick(record, { key: 'view' })}
            icon={<EyeOutlined className="text-dark" />}
          />
          <Button
            className="btn btn-none"
            onClick={() => handleMenuClick(record, { key: 'edit' })}
            icon={<EditOutlined className="text-dark" />}
          />
        </Space>
      ),
      align: 'center',
    },
  ];

  return (
    <div className="table-responsive">
      {loading && <Loader />}

      <div className="d-flex justify-content-between mb-3">
        <div className="heading-challenge">Physical Marathon List</div>
        <div className="text-center align-self-center count-text">
          Total Marathons: {filteredMarathons.length}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 col-md-10 col-sm-12 mb-2">
          <Search
            placeholder="Search by marathon title"
            allowClear
            enterButton={
              <Button style={{ backgroundColor: '#dc0272', color: '#fff' }}>
                Search
              </Button>
            }
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12">
          <Select
            value={selectedStatus}
            className="w-100"
            onChange={handleStatusFilterChange}
          >
            <Option value="all">All</Option>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredMarathons}
        pagination={{ pageSize: 20 }}
        scroll={{ x: 'max-content' }}
      />
      <PhsicalMarathonDetailsShow
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        marathon_id={selectedMarathonId}
      />
      <PhysicalMarathonDetailsUpdateModal
        visible={detailVisible}
        onClose={() => setDetailsVisible(false)}
        marathon_id={selectedMarathonId}
      />
    </div>
  );
};

export default PhysicalMArathonList;
