import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { toast } from 'react-toastify';
import Loader from '../Loader/Loader';
import {
  createUpdateTermsConditions,
  fetchTermsCondition,
} from '../../Services/ApiServices';

const TermsConditions = () => {
  const [value, setValue] = useState('');
  const [termsConditionId, setTermsConditionId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTermsConditionData = async () => {
      setLoading(true);
      try {
        const termsCondition = await fetchTermsCondition();
        setValue(termsCondition.TermsAndCondition);
        setTermsConditionId(termsCondition._id);
      } catch (error) {
        toast.error('Failed to fetch terms and conditions');
      } finally {
        setLoading(false);
      }
    };

    fetchTermsConditionData();
  }, []);

  const handleCreateUpdateTermsCondition = async () => {
    setLoading(true);
    try {
      await createUpdateTermsConditions({
        TermsAndCondition: value,
        _id: termsConditionId,
      });
      toast.success('Terms and conditions updated successfully');
      const updatedTermsCondition = await fetchTermsCondition();
      setValue(updatedTermsCondition.TermsAndCondition);
      setTermsConditionId(updatedTermsCondition._id);
    } catch (error) {
      toast.error('Failed to update terms and conditions');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-3">
      <h3 className="mb-3 heading-challenge">Terms & Conditions</h3>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ReactQuill
            className="mb-3"
            value={value}
            onChange={setValue}
            placeholder="Write your Terms & Conditions here..."
            modules={{
              toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                ['link'],
                ['clean'],
              ],
            }}
            formats={[
              'header',
              'font',
              'size',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'video',
            ]}
          />
          <div className="d-flex justify-content-end mt-2">
            <button
              style={{ background: '#dc0272' }}
              className="btn fw-bold text-light"
              onClick={handleCreateUpdateTermsCondition}
            >
              Update Terms & Conditions
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TermsConditions;
