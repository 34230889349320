import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import {
  fetchUserDetails,
  updateUserProfile,
} from '../../Services/ApiServices';
import Loader from '../Loader/Loader';

const { Option } = Select;

const UserEditModal = ({ visible, onClose, user_id, onSave }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userData = await fetchUserDetails(user_id);

        form.setFieldsValue({
          first_name: userData?.first_name,
          last_name: userData?.last_name,
          email_id: userData?.email_id,
          gender: userData?.gender ? userData.gender.toString() : undefined,
          status: userData?.status === 1 ? 'active' : 'inactive',
          nationality: userData?.nationality,
          points_earned: userData?.points_earned,
          updatedAt: formatDateTime(userData?.updatedAt),
          lastLoginAt: formatDateTime(userData?.lastLoginAt),
        });
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (visible && user_id) {
      fetchUserData();
    }
  }, [visible, user_id, form]);

  const formatDateTime = (isoString) => {
    if (!isoString) return '';
    const date = new Date(isoString);
    return date.toISOString().replace('Z', '');
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email_id: values.email_id || '',
        gender: parseInt(values.gender),
        status: values.status === 'active' ? 1 : 2,
        nationality: values.nationality,
        points_earned: values.points_earned,
        userId: user_id,
      };

      await updateUserProfile(payload);
      onSave(payload);
      onClose();
    } catch (error) {
      console.error('Failed to update user profile:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      title="Edit User"
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSave}
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      {loading ? <Loader spinning={true} /> : null}

      <Form form={form} layout="vertical" name="user_edit_form">
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[{ required: true, message: 'Please input the first name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[{ required: true, message: 'Please input the last name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email_id"
          label="Email"
          // rules={[{ required: true, message: 'Please input the email!' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="gender"
          label="Gender"
          rules={[{ required: true, message: 'Please select the gender!' }]}
        >
          <Select>
            <Option value="1">Male</Option>
            <Option value="2">Female</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select the status!' }]}
        >
          <Select>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="nationality"
          label="Nationality"
          rules={[{ required: true, message: 'Please input the nationality!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="points_earned"
          label="Points Earned"
          rules={[
            { required: true, message: 'Please input the points earned!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="updatedAt" label="Updated At">
          <Input disabled />
        </Form.Item>
        <Form.Item name="lastLoginAt" label="Last Login At">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserEditModal;
