import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import {
  createOrUpdateChallenge,
  uploadImageToAPI,
  fetchUsersList,
} from '../../Services/ApiServices';
import DateTime from 'react-datetime';
import { toast } from 'react-toastify';
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import MapChallenge from './MapChallenge';
import './AddChallenges.css';

const { Option } = Select;
const AddChallenges = () => {
  const [challengeDetails, setChallengeDetails] = useState({
    challenge_name: '',
    challenge_image: [],
    challenge_type: '',
    challange_limit: '',
    description: '',
    start_time: '',
    end_time: '',
    time_limit: '',
    points: '',
    in_challenge_rewards: '',
    badge: {
      badge_description: '',
      badge_name: '',
      badge_image: '',
    },
    gifted_to: [],
    challenge_rule: {
      rule1: '',
      rule2: '',
    },
    clip: [],
    original_price: '',
    discount: '',
    challengeCode: '',
    sub_challenges: [
      {
        name: '',
        points: '',
        time_limit: '',
        description: '',
        latitude: '',
        longitude: '',
      },
    ],
  });

  const [users, setUsers] = useState([]);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [selectedChallengeType, setSelectedChallengeType] = useState('');
  const [imageResponse, setImageResponse] = useState([]);
  const [clipResponse, setClipResponse] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedUserId, setSelectedUserId] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleSearch = (value) => {
    setSearchValue(value);
    let userData = users?.users;
    const selectedUser = userData.find((user) => {
      // user.first_name + ' ' + user.last_name === value;
    });
    if (selectedUser) {
      setChallengeDetails({
        ...challengeDetails,
        gifted_to: [selectedUser._id],
      });
      setSelectedUserId(selectedUser._id);
    }
  };

  const handleSelectChange = (value) => {
    setSelectedUserId(value);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const fetchUsers = async () => {
      const userList = await fetchUsersList();
      setUsers(userList);
    };

    fetchUsers();
  }, []);

  const handleLocationSelect = (index, lat, lng) => {
    const updatedSubchallenges = [...challengeDetails.sub_challenges];
    updatedSubchallenges[index].latitude = lat.toString();
    updatedSubchallenges[index].longitude = lng.toString();
    setChallengeDetails({
      ...challengeDetails,
      sub_challenges: updatedSubchallenges,
    });
    setSelectedLocation({ lat, lng });
  };

  const handleCloseMapModal = () => {
    setMapModalOpen(false);
  };

  const handleMapClick = () => {
    setMapModalOpen(true);
  };

  const handleChallengeTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedChallengeType(selectedType);

    if (selectedType === 'unlimited') {
      setChallengeDetails({
        ...challengeDetails,
        challenge_type: selectedType,
        challange_limit: '',
      });
    } else {
      setChallengeDetails({
        ...challengeDetails,
        challenge_type: selectedType,
      });
    }
  };

  useEffect(() => {
    setChallengeDetails((prevDetails) => ({
      ...prevDetails,
      challange_limit: selectedChallengeType
        ? challengeDetails?.challange_limit
        : '',
    }));
  }, [selectedChallengeType]);

  const handleChangeRule = (ruleNumber, value) => {
    setChallengeDetails({
      ...challengeDetails,
      challenge_rule: {
        ...challengeDetails.challenge_rule,
        [`rule${ruleNumber}`]: value,
      },
    });
  };

  const handleChange = (index, fieldName, e) => {
    const updatedSubchallenges = [...challengeDetails.sub_challenges];
    updatedSubchallenges[index][fieldName] = e.target.value;
    setChallengeDetails({
      ...challengeDetails,
      sub_challenges: updatedSubchallenges,
    });
  };

  const handleAddField = () => {
    setChallengeDetails({
      ...challengeDetails,
      sub_challenges: [
        ...challengeDetails.sub_challenges,
        {
          name: '',
          points: '',
          time_limit: '',
          description: '',
          latitude: '',
          longitude: '',
        },
      ],
    });
  };

  const handleRemoveField = (index) => {
    const updatedSubchallenges = [...challengeDetails.sub_challenges];
    updatedSubchallenges.splice(index, 1);
    setChallengeDetails({
      ...challengeDetails,
      sub_challenges: updatedSubchallenges,
    });
  };

  const handleChangeFile = async (e) => {
    const files = e?.target?.files || [e];
    const formData = new FormData();

    for (let file of files) {
      formData.append('file', file);
    }
    try {
      let response;
      let imageArr = [];
      response = await uploadImageToAPI(formData);

      if (response && response.response) {
        imageArr = imageResponse ? [...imageResponse] : [];
        if (Array.isArray(response.response)) {
          imageArr.push(...response.response);
        } else {
          imageArr.push(response.response);
        }
        setImageResponse(imageArr);
        setChallengeDetails((prevDetails) => ({
          ...prevDetails,
          challenge_image: imageArr,
        }));
      } else {
        console.error('No response data for image upload');
      }
    } catch (error) {
      toast.error(`Error uploading`);
    }
  };

  const handleChangeBadge = async (e) => {
    const file = e?.target?.files[0] || e;
    const formData = new FormData();
    formData.append('file', file);
    try {
      const imageUrl = await uploadImageToAPI(formData);
      if (imageUrl) {
        setChallengeDetails({
          ...challengeDetails,
          badge_image: imageUrl,
        });
      }
    } catch (error) {
      toast.error(`Error uploading`);
    }
  };

  const handleChangeClip = async (e, type) => {
    let file = e?.target?.files[0];

    const formData = new FormData();
    formData.append('file', file);

    try {
      let response;

      response = await uploadImageToAPI(formData);

      if (response) {
        if (Array.isArray(response.response)) {
          setClipResponse(response.response);
        } else {
          console.error(response.response.message);
        }
      }
    } catch (error) {
      toast.error(`Error uploading ${type}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!challengeDetails.start_time || !challengeDetails.end_time) {
      toast.error('Please select start and end time');
      return;
    }

    try {
      const formattedStartTime = challengeDetails?.start_time
        ? new Date(challengeDetails.start_time).toISOString()
        : new Date().toISOString();
      const formattedEndTime = challengeDetails?.end_time
        ? new Date(challengeDetails.end_time).toISOString()
        : new Date().toISOString();

      const updatedSubChallenges = challengeDetails.sub_challenges.map(
        (subChallenge) => ({
          ...subChallenge,
          latitude: subChallenge.latitude.toString(),
          longitude: subChallenge.longitude.toString(),
        })
      );

      const updatedChallengeDetails = {
        ...challengeDetails,

        gifted_to: selectedUserId,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        challenge_image: imageResponse,
        clip: clipResponse,
        sub_challenges: updatedSubChallenges,
      };
      updatedChallengeDetails.badge = {
        badge_name: updatedChallengeDetails?.badge_name,
        badge_description: updatedChallengeDetails?.badge_description,
        badge_image: updatedChallengeDetails?.badge_image,
      };
      delete updatedChallengeDetails?.badge_name;
      delete updatedChallengeDetails?.badge_description;
      delete updatedChallengeDetails?.badge_image;

      const response = await createOrUpdateChallenge(updatedChallengeDetails);
      console.log('response: ', response);

      if (response.success === true) {
        toast.success(response.message);
        navigate('/');

        setChallengeDetails({
          challenge_name: '',
          challenge_image: [],
          challenge_type: '',
          challange_limit: '',
          description: '',
          start_time: '',
          end_time: '',
          time_limit: '',
          points: '',
          in_challenge_rewards: '',
          badge_description: '',
          badge_name: '',
          badge_image: '',
          gifted_to: [],
          challenge_rule: {
            rule1: '',
            rule2: '',
          },
          clip: '',
          original_price: '',
          discount: '',
          challengeCode: '',
          sub_challenges: [
            {
              name: '',
              points: '',
              time_limit: '',
              description: '',
              latitude: '',
              longitude: '',
            },
          ],
        });
      } else {
        handleSubmissionError(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const removeImage = (indexToRemove, event) => {
    event.preventDefault();
    setSelectedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleSubmissionError = (errorMessage) => {
    if (errorMessage.includes('sub_challenges')) {
      toast.error('Sub challenge field cannot be empty');
    } else {
      toast.error('An error occurred: ' + errorMessage);
    }
  };

  return (
    <>
      <Container>
        <div className="text-center my-0">
          <h3 className="text-color">Add Challenges</h3>
        </div>
        <Row className="justify-content-md-center">
          <Col lg={12} sm={12} md={12} xs={12}>
            <div className="card mt-2 mx-auto p-4 bg-light">
              <div className="card-body bg-light">
                <Form>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="challengeName">
                        <Form.Label>Add Challenge Name</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-2"
                          value={challengeDetails.challenge_name}
                          onChange={(e) =>
                            setChallengeDetails({
                              ...challengeDetails,
                              challenge_name: e.target.value,
                            })
                          }
                          placeholder="Please enter challenge Name"
                        />
                        {/* {challengeDetails.challenge_name === '' && (
                        <Form.Text className="text-danger">
                          Please enter challenge name
                        </Form.Text>
                      )} */}
                      </Form.Group>
                    </Col>

                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Challenge Image</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          accept="image/*"
                          // onChange={handleChallangeFile}
                          onChange={(e) => {
                            // handleChangeFile(e, 'image'); // Assuming you want to handle clips too
                            handleChangeFile(e);
                          }}
                        />
                      </Form.Group>
                      {selectedImages.length > 0 && (
                        <div>
                          <p>Selected Images:</p>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selectedImages.map((image, index) => (
                              <div
                                key={index}
                                style={{
                                  position: 'relative',
                                  display: 'inline-block',
                                  margin: '5px',
                                }}
                              >
                                <img
                                  src={image}
                                  alt={`Selected ${index + 1}`}
                                  style={{ maxWidth: '100px' }}
                                />
                                <button
                                  onClick={(event) => removeImage(index, event)}
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    background: 'red',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="challengeType">
                        <Form.Label>Challenge Type</Form.Label>
                        <Form.Control
                          as="select"
                          className="mb-2"
                          value={selectedChallengeType}
                          onChange={handleChallengeTypeChange}
                        >
                          <option value="">Select</option>
                          <option value="limited">Limited</option>
                          <option value="unlimited">Unlimited</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="challengeName">
                        <Form.Label>Challenge Limit</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-2"
                          value={challengeDetails.challange_limit}
                          onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, '');
                            setChallengeDetails({
                              ...challengeDetails,
                              challange_limit: value,
                            });
                          }}
                          placeholder="Please enter challenge Limit"
                          disabled={selectedChallengeType === 'unlimited'}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      className="mb-2"
                      rows={3}
                      placeholder="Description"
                      value={challengeDetails.description}
                      onChange={(e) =>
                        setChallengeDetails({
                          ...challengeDetails,
                          description: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="start_time">
                        <Form.Label>Start Time</Form.Label>
                        <DateTime
                          className="mb-2"
                          value={challengeDetails.start_time}
                          onChange={(date) =>
                            setChallengeDetails({
                              ...challengeDetails,
                              start_time: date,
                            })
                          }
                          inputProps={{
                            placeholder: 'Please enter start time',
                          }}
                          isValidDate={(currentDate) => {
                            const startOfToday = moment().startOf('day');
                            return (
                              currentDate.isAfter(startOfToday) ||
                              currentDate.isSame(startOfToday, 'day')
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="points">
                        <Form.Label>End Time</Form.Label>
                        <DateTime
                          className="mb-2"
                          value={challengeDetails.end_time}
                          onChange={(date) =>
                            setChallengeDetails({
                              ...challengeDetails,
                              end_time: date,
                            })
                          }
                          inputProps={{ placeholder: 'Please enter end time' }}
                          isValidDate={(currentDate) => {
                            return (
                              currentDate.isAfter(
                                moment(challengeDetails.start_time)
                              ) ||
                              currentDate.isSame(
                                moment(challengeDetails.start_time),
                                'day'
                              )
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="days">
                        <Form.Label>Time Limit</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-2 "
                          value={challengeDetails.time_limit}
                          onChange={(e) =>
                            setChallengeDetails({
                              ...challengeDetails,
                              time_limit: e.target.value,
                            })
                          }
                          placeholder="Please enter Time Limit"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="points">
                        <Form.Label>Points</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-2"
                          value={challengeDetails.points}
                          onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, '');
                            setChallengeDetails({
                              ...challengeDetails,
                              points: value,
                            });
                          }}
                          placeholder="Please enter points"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="start_time">
                        <Form.Label>In Challenge Rewards</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-2 "
                          value={challengeDetails.in_challenge_rewards}
                          onChange={(e) =>
                            setChallengeDetails({
                              ...challengeDetails,
                              in_challenge_rewards: e.target.value,
                            })
                          }
                          placeholder="Please enter in challenge rewards"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="points">
                        <Form.Label>Gifted To</Form.Label>

                        {/* <Select
                          mode="multiple"
                          showSearch
                          style={{ width: '100%', height: '36px' }}
                          placeholder={
                            <span style={{ color: 'gray' }}>
                              Search by name
                            </span>
                          }
                          optionFilterProp="children"
                          value={selectedUserId}
                          onChange={handleSelectChange}
                          onSearch={handleSearch}
                          filterOption={(input, option) => {
                            const fullName = `${option.first_name} ${option.last_name}`;
                            const searchTerms = input.toLowerCase().split(' ');
                            return searchTerms.every(
                              (term) =>
                                fullName.toLowerCase().includes(term) ||
                                option.first_name
                                  .toLowerCase()
                                  .includes(term) ||
                                option.last_name.toLowerCase().includes(term)
                            );
                          }}
                        >
                          {users
                            .filter(
                              (user) =>
                                user.first_name
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase()) ||
                                user.last_name
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase())
                            )
                            .map((user) => (
                              <Option
                                key={user._id}
                                value={user._id}
                                first_name={user.first_name}
                                last_name={user.last_name}
                              >
                                {user.first_name} {user.last_name}
                              </Option>
                            ))}
                        </Select> */}

                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: '100%', height: '36px' }}
                          placeholder={
                            <span style={{ color: 'gray' }}>
                              Search by name
                            </span>
                          }
                          optionFilterProp="children"
                          value={selectedUserId}
                          onChange={handleSelectChange}
                          onSearch={handleSearch}
                          filterOption={(input, option) => {
                            const fullName = `${option.first_name} ${option.last_name}`;
                            const searchTerms = input.toLowerCase().split(' ');
                            return searchTerms.every(
                              (term) =>
                                fullName.toLowerCase().includes(term) ||
                                option.first_name
                                  .toLowerCase()
                                  .includes(term) ||
                                option.last_name.toLowerCase().includes(term)
                            );
                          }}
                        >
                          {Array.isArray(users?.users) &&
                            (() => {
                              const filteredUsers = [];
                              users?.users.forEach((user) => {
                                if (
                                  (user.first_name &&
                                    user.first_name
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase())) ||
                                  (user.last_name &&
                                    user.last_name
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase()))
                                ) {
                                  filteredUsers.push(user);
                                }
                              });

                              return filteredUsers.map((user) => (
                                <Option
                                  key={user._id}
                                  value={user._id}
                                  first_name={user.first_name}
                                  last_name={user.last_name}
                                >
                                  {user.first_name} {user.last_name}
                                </Option>
                              ));
                            })()}
                        </Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="points">
                        <Form.Label>Clip </Form.Label>
                        <Form.Control
                          type="file"
                          accept="video/*"
                          onChange={(e) => handleChangeClip(e, 'clip')}
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="start_time">
                        <Form.Label>Original Price</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-2"
                          value={challengeDetails.original_price}
                          onChange={(e) => {
                            const value = e.target.value;
                            setChallengeDetails({
                              ...challengeDetails,
                              original_price: value,
                            });
                          }}
                          placeholder="Please enter Original Price"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label>Challenge Rule</Form.Label>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="challengeName">
                        <Form.Control
                          type="text"
                          value={challengeDetails.challenge_rule?.rule1}
                          onChange={(e) => handleChangeRule(1, e.target.value)}
                          placeholder="Rule 1"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Control
                          type="text"
                          value={challengeDetails.challenge_rule?.rule2}
                          onChange={(e) => handleChangeRule(2, e.target.value)}
                          placeholder="Rule 2"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="points">
                        <Form.Label>Discount </Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-2"
                          value={challengeDetails.discount}
                          onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, '');
                            setChallengeDetails({
                              ...challengeDetails,
                              discount: value,
                            });
                          }}
                          placeholder="Please enter Discount"
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="badgeForm" className="mb-3">
                        <Form.Label>Badge Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter badge name"
                          value={challengeDetails.badge_name}
                          onChange={(e) =>
                            setChallengeDetails({
                              ...challengeDetails,
                              badge_name: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="badgeDescription" className="mb-3">
                        <Form.Label>Badge Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={challengeDetails.badge_description}
                          onChange={(e) =>
                            setChallengeDetails({
                              ...challengeDetails,
                              badge_description: e.target.value,
                            })
                          }
                          placeholder="Enter badge description"
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload Badge Image</Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleChangeBadge(e)}
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={12} md={6} lg={6}>
                      <Form.Group controlId="points">
                        <Form.Label>Challenge Code</Form.Label>
                        <Form.Control
                          type="text"
                          className="mb-2"
                          value={challengeDetails.challengeCode}
                          onChange={(e) =>
                            setChallengeDetails({
                              ...challengeDetails,
                              challengeCode: e.target.value,
                            })
                          }
                          placeholder="Please enter challengeCode"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-2">
                    <h3 className="mt-4 mb-4">Sub Challenge</h3>
                    {challengeDetails.sub_challenges.map((field, index) => (
                      <div key={index}>
                        <Form.Control
                          type="text"
                          className="mb-2"
                          placeholder="Challenge Name"
                          value={field.name}
                          onChange={(e) => handleChange(index, 'name', e)}
                        />

                        <Form.Control
                          as="textarea"
                          className="mb-2"
                          rows={3}
                          placeholder="Description"
                          value={field.description}
                          onChange={(e) =>
                            handleChange(index, 'description', e)
                          }
                        />
                        <Row>
                          <Col sm={12} md={4} lg={4} className="mb-2">
                            <Form.Control
                              type="text"
                              placeholder="Time Limits"
                              value={field.time_limit}
                              onChange={(e) =>
                                handleChange(index, 'time_limit', e)
                              }
                            />
                          </Col>

                          <Col sm={12} md={4} lg={4} className="mb-2">
                            <Form.Control
                              type="text"
                              placeholder="Points"
                              value={field.points}
                              onChange={(e) => handleChange(index, 'points', e)}
                            />
                          </Col>

                          <Col sm={12} md={4} lg={4} className="mb-2">
                            <Button onClick={() => handleMapClick(index)}>
                              Open Map
                            </Button>
                          </Col>
                        </Row>

                        <Modal
                          show={mapModalOpen}
                          onHide={handleCloseMapModal}
                          centered
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Select loction on Map</Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="mb-3">
                            <MapChallenge
                              handleLocationSelect={(lat, lng) =>
                                handleLocationSelect(index, lat, lng)
                              }
                              initialLocation={selectedLocation}
                            />
                          </Modal.Body>
                        </Modal>

                        <div className="d-flex justify-content-between mb-5">
                          <Button
                            variant="danger"
                            onClick={() => handleRemoveField(index)}
                            className="me-2"
                            disabled={
                              challengeDetails.sub_challenges.length === 1
                            }
                          >
                            Remove
                          </Button>
                          {index ===
                            challengeDetails.sub_challenges.length - 1 && (
                            <Button variant="success" onClick={handleAddField}>
                              Add
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                  </Form.Group>
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="success"
                      className="border-0 col-6 p-2 fs-6 fw-bold"
                      onClick={(e) => handleSubmit(e)}
                      style={{ background: '#dc0272' }}
                    >
                      Add Challenge
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddChallenges;
