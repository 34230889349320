import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { loginToAPI } from '../../Services/ApiServices';
import MarathonLogo from '../../Assets/AuthImages/marathon-logo.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Loader from '../Loader/Loader';
import BackgroundImage from '../../Assets/AuthImages/welcome.jpg';
import { toast } from 'react-toastify';
import './login.css';

const Login = () => {
  const navigate = useNavigate();
  const initialValue = {
    email_id: '',
    password: '',
  };
  const [detail, setDetails] = useState(initialValue);
  const [formError, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
    }
  }, [formError, isSubmit]);

  const handleClick = async (e) => {
    e.preventDefault();
    const errors = validate(detail);
    console.log('errors', errors);
    setFormErrors(errors);
    setIsSubmit(true);

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      try {
        const response = await loginToAPI(detail);
        setIsSubmitting(false);

        if (response && response.success) {
          toast.success(response.message);
          navigate('/appMenu');
        } else {
          setErrorMessage(
            'The email or password you entered is incorrect. Please try again.'
          );
        }
      } catch (error) {
        console.error('Login failed:', error);
        setIsSubmitting(false);
        setErrorMessage(
          'The email or password you entered is incorrect. Please try again.'
        );
      }
    } else {
      setErrorMessage('Please fill in all required fields correctly.');
    }
  };

  const validate = (details) => {
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/i;
    if (!regex.test(details.email_id)) {
      error.email_id = 'This is not a valid email.';
    }
    if (!details.password) {
      error.password = 'Password is required.';
    } else if (details.password.length < 4) {
      error.password = 'Password should be more than 4 characters.';
    }
    return error;
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      {isSubmitting && <Loader />}
      <div
        className="container-fluid d-flex flex-column"
        style={{ height: '100vh' }}
      >
        <div className="row flex-grow-1">
          <div className="col-md-6 col-sm-12 p-0 position-relative bgContainer">
            <div className="bgImgContainer">
              <img src={BackgroundImage} alt="Background" className="bgImg" />
            </div>
            <div className="position-absolute top-50 start-0 translate-middle-y text-white mb-5 p-4 z-index-2">
              <h2 className="textsize">
                <b>Every Step Counts, Every Mile Matters</b>
              </h2>
            </div>
            <img src={MarathonLogo} alt="Logo" className="logoimg" />
          </div>
          <div className="col-sm-12 col-md-6 bg-dark d-flex align-items-center justify-content-center textContainer">
            <div className="card border-0 bg-dark cardWidth">
              <div className="d-flex">
                <h3 className="mx-auto text-light my-3">Admin Login</h3>
              </div>
              <Form onSubmit={handleClick}>
                <Form.Group
                  className="inputFieldGroup mb-3"
                  controlId="formEmail"
                >
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email_id"
                    value={detail.email_id}
                    onChange={handleChange}
                    className="form-control inputField border-0 p-2 placeholder-white"
                    style={{ background: 'black', color: 'white' }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.email_id}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="inputFieldGroup mb-3"
                  controlId="formPassword"
                >
                  <div className="position-relative">
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={detail.password}
                      onChange={handleChange}
                      className="form-control inputField border-0 p-2 placeholder-white"
                      style={{ background: 'black', color: 'white' }}
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon2"
                    />
                    <span
                      className="position-absolute end-0 top-50 translate-middle-y pe-2"
                      onClick={toggleShowPassword}
                      style={{ cursor: 'pointer', color: 'white' }}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </Form.Group>
                {formError.password && (
                  <div className="error-message">{formError.password}</div>
                )}

                <div className="text-center btnGroup w-100">
                  <button className="btn-signin" type="submit">
                    Sign in
                  </button>
                </div>
              </Form>
              {errorMessage && (
                <div className="alert alert-danger mt-3">{errorMessage}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
