import React from 'react';
import { Spin } from 'antd';

const Loader = ({ spinning }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
      }}
    >
      <Spin spinning={spinning} size="large" />
    </div>
  );
};

export default Loader;
