import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Avatar, Tabs, Card } from 'antd';
import { fetchUserDetails } from '../../Services/ApiServices';
import AvatarImage from '../../Assets/Images/avatar.jpg';
import Loader from '../Loader/Loader';

const { TabPane } = Tabs;
const { Meta } = Card;

const tabListNoTitle = [
  {
    key: 'friendsList',
    tab: 'Friends List',
  },
  {
    key: 'challenges_completed',
    tab: 'Challenges Completed',
  },
  {
    key: 'challenge_purchase',
    tab: 'Challenge Purchase',
  },
  {
    key: 'challenge_list',
    tab: 'Challenge List',
  },
];

const UserDetails = ({ visible, onClose, user_id }) => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState(tabListNoTitle[0].key);

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userData = await fetchUserDetails(user_id);
        setUserData(userData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (visible && user_id) {
      fetchUserData();
    }
  }, [visible, user_id]);

  const friendsListCount = userData?.friendsList
    ? userData.friendsList.length
    : 0;
  const challengesCompletedCount = userData?.challenges_completed
    ? userData.challenges_completed.length
    : 0;
  const challengePurchaseCount = userData?.challenge_purchase
    ? userData.challenge_purchase.length
    : 0;
  const challengeListCount = userData?.challenge_list
    ? userData.challenge_list.length
    : 0;
  const friendRequestCount = userData?.friendRequest
    ? userData.friendRequest.length
    : 0;

  return (
    <Modal
      title="User Details"
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      width={1200}
      style={{ padding: 0 }}
    >
      {loading || !userData ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Loader spinning={true} />
        </div>
      ) : (
        userData && (
          <div>
            <Row>
              <Col xs={24} md={6} className="me-2">
                <Card
                  style={{ width: '100%' }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 200,
                      }}
                    >
                      {userData.avatar ? (
                        <Avatar size={120} src={userData.avatar} />
                      ) : (
                        <img
                          src={AvatarImage}
                          alt="Default Avatar"
                          style={{
                            maxWidth: '150px',
                            borderRadius: '50%',
                          }}
                        />
                      )}
                    </div>
                  }
                >
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <Meta
                      title={`${userData.first_name} ${userData.last_name}`}
                      description={userData.email_id}
                    />
                    <div className="mt-2 d-flex justify-content-around">
                      <p>Gender: {userData.gender === 1 ? 'Male' : 'Female'}</p>
                      <p>Nationality: {userData.nationality}</p>
                    </div>
                  </div>

                  <Card.Grid style={{ width: '100%' }}>
                    <Row className="mb-1">
                      <Col xs={18} sm={18} lg={18} md={18}>
                        Friends List:
                      </Col>
                      <Col className="text-end" xs={6} sm={6} lg={6} md={6}>
                        <span className="text-primary">{friendsListCount}</span>
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col xs={18} sm={18} lg={18} md={18}>
                        Friends Request:
                      </Col>
                      <Col className="text-end" xs={6} sm={6} lg={6} md={6}>
                        <span className="text-primary">
                          {friendRequestCount}
                        </span>
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <Col xs={18} sm={18} lg={18} md={18}>
                        Challenges Completed:
                      </Col>
                      <Col className="text-end" xs={6} sm={6} lg={6} md={6}>
                        <span className="text-primary">
                          {challengesCompletedCount}
                        </span>
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <Col xs={18} sm={18} lg={18} md={18}>
                        Challenge Purchases:
                      </Col>
                      <Col className="text-end" xs={6} sm={6} lg={6} md={6}>
                        <span className="text-primary">
                          {challengePurchaseCount}
                        </span>
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <Col xs={18} sm={18} lg={18} md={18}>
                        Challenge List:
                      </Col>
                      <Col className="text-end" xs={6} sm={6} lg={6} md={6}>
                        <span className="text-primary">
                          {challengeListCount}
                        </span>
                      </Col>
                    </Row>
                  </Card.Grid>
                </Card>
              </Col>
              <Col xs={24} md={17}>
                <Card style={{ width: '100%' }}>
                  <Tabs activeKey={activeTabKey} onChange={onTabChange}>
                    {tabListNoTitle.map((tab) => (
                      <TabPane
                        tab={tab.tab}
                        key={tab.key}
                        style={{ minHeight: '332px' }}
                      >
                        <p>{`Content for ${tab.tab}`}</p>
                      </TabPane>
                    ))}
                  </Tabs>
                </Card>
              </Col>
            </Row>
          </div>
        )
      )}
    </Modal>
  );
};

export default UserDetails;
