import axios from 'axios';
import Cookies from 'js-cookie';

export const makeJsonApiRequest = async (
  method,
  endpoint,
  jsonData = null,
  token = null
) => {
  try {
    let headers = {};
    const storedToken = Cookies.get('token');

    if (storedToken) {
      headers = {
        'Content-Type': 'application/json',
        Authorization: `${storedToken}`,
      };
    } else {
      headers = {
        'Content-Type': 'application/json',
      };
    }

    const config = {
      method,
      url: endpoint,
      data: jsonData,
      headers,
    };

    const response = await axios(config);

    return response;
  } catch (error) {
    return error.response;
  }
};

export const makeJsonFormDataApiRequest = async (
  method,
  endpoint,
  jsonData = null,
  token = null
) => {
  try {
    let headers = {};
    const storedToken = Cookies.get('token');

    if (storedToken) {
      headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `${storedToken}`,
      };
    } else {
      headers = {
        'Content-Type': 'multipart/form-data',
      };
    }

    const config = {
      method,
      url: endpoint,
      data: jsonData,
      headers,
    };

    const response = await axios(config);

    return response;
  } catch (error) {
    return error.response;
  }
};
