import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Row, Col, DatePicker } from 'antd';
import {
  fetchMarathonDetails,
  createPhysicalMarathon,
} from '../../Services/ApiServices';
import { toast } from 'react-toastify';
import moment from 'moment';

const { Option } = Select;

const formatDateTimeForPicker = (isoString) => {
  if (!isoString) return null;
  return moment(isoString);
};

const PhysicalMarathonDetailsUpdateModal = ({
  marathon_id,
  onClose,
  visible,
}) => {
  const [marathonDetails, setMarathonDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible && marathon_id) {
      fetchMarathon();
    }
  }, [marathon_id, visible]);

  const fetchMarathon = async () => {
    setLoading(true);
    try {
      const response = await fetchMarathonDetails(marathon_id);
      setMarathonDetails(response);
      form.setFieldsValue({
        marathon_title: response.marathon_title,
        startDate: formatDateTimeForPicker(response.startDate),
        endDate: formatDateTimeForPicker(response.endDate),
        startPointAddress: response.startPointAddress,
        endPointAddress: response.endPointAddress,
        age: response.age,
        gender: response.gender ? response.gender.toString() : undefined,
        contact: response.contact,
        marathonCategory: response.marathonCategory,
        pickupItems: response.pickupItems,
      });
    } catch (error) {
      console.error('Error fetching marathon details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const payload = {
        _id: marathon_id,
        marathon_title: values.marathon_title,
        startDate: values.startDate.format('YYYY-MM-DD'),
        endDate: values.endDate.format('YYYY-MM-DD'),
        startPointAddress: values.startPointAddress,
        endPointAddress: values.endPointAddress,
        age: values.age,
        gender: values.gender,
        contact: values.contact,
        marathonCategory: values.marathonCategory,
        pickupItems: values.pickupItems,
      };
      const response = await createPhysicalMarathon(payload);
      toast.success(response.message);
      onClose();
    } catch (error) {
      console.error('Error updating marathon details:', error);
      toast.error(`Failed to update marathon details, ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleAddItem = () => {
    form.setFieldsValue({
      pickupItems: [
        ...form.getFieldValue('pickupItems'),
        { itemName: '', quantity: '' },
      ],
    });
  };

  // Custom validator function for end date
  const validateEndDate = (_, value) => {
    const startDate = form.getFieldValue('startDate');
    if (startDate && value && value.isBefore(startDate, 'day')) {
      return Promise.reject('End date must be after start date');
    }
    return Promise.resolve();
  };

  // Disable dates before the selected start date for endDate DatePicker
  const disabledEndDate = (current) => {
    const startDate = form.getFieldValue('startDate');
    return current && current.isBefore(startDate, 'day');
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      width={800}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleUpdate}
          loading={loading}
          className="text-center w-100"
          style={{
            borderRadius: '8px',
            background: '#dc0272',
            fontWeight: '600',
          }}
        >
          Update
        </Button>,
      ]}
    >
      <h3 style={{ color: '#dc0272' }} className="text-center mb-3">
        Physical Marathon Challange Update
      </h3>
      <Form form={form} layout="vertical">
        <Form.Item
          label="Marathon Title"
          name="marathon_title"
          rules={[{ required: true, message: 'Please enter marathon title' }]}
        >
          <Input />
        </Form.Item>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[
                { required: true, message: 'Please select start date' },
                {
                  validator: (_, value) =>
                    value && value.isBefore(moment(), 'day')
                      ? Promise.reject('Start date must be in the future')
                      : Promise.resolve(),
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY hh:mm A"
                className="w-100"
                showTime={{ use12Hours: true }}
                disabledDate={(currentDate) =>
                  currentDate && currentDate.isBefore(moment().startOf('day'))
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[
                { required: true, message: 'Please select end date' },
                { validator: validateEndDate },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY hh:mm A"
                className="w-100"
                showTime={{ use12Hours: true }}
                disabledDate={disabledEndDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Start Point Address"
          name="startPointAddress"
          rules={[
            { required: true, message: 'Please enter start point address' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="End Point Address"
          name="endPointAddress"
          rules={[
            { required: true, message: 'Please enter end point address' },
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Age"
              name="age"
              rules={[{ required: true, message: 'Please enter age' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: 'Please select gender' }]}
            >
              <Select>
                <Option value="1">Male</Option>
                <Option value="2">Female</Option>
                <Option value="other">Open</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Contact"
              name="contact"
              rules={[
                { required: true, message: 'Please enter contact' },
                {
                  pattern: /^[0-9]{10}$/,
                  message: 'Please enter a valid 10-digit phone number',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Marathon Category"
              name="marathonCategory"
              rules={[
                { required: true, message: 'Please select marathon category' },
              ]}
            >
              <Select placeholder="Select Category">
                <Option value="5k">5K</Option>
                <Option value="10k">10K</Option>
                <Option value="half-marathon">Half Marathon</Option>
                <Option value="full-marathon">Full Marathon</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="pickupItems">
          {(fields, { add, remove }) => (
            <div className="pickup-items-section">
              <h3>Pickup Items:</h3>
              {fields.map((field, index) => (
                <div key={field.key} className="pickup-item">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Item Name"
                        name={[field.name, 'itemName']}
                        fieldKey={[field.fieldKey, 'itemName']}
                        rules={[
                          { required: true, message: 'Please enter item name' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Quantity"
                        name={[field.name, 'quantity']}
                        fieldKey={[field.fieldKey, 'quantity']}
                        rules={[
                          { required: true, message: 'Please enter quantity' },
                        ]}
                      >
                        <Input type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col span={12}>
                      <Button
                        danger
                        onClick={() => remove(field.name)}
                        disabled={fields.length === 1}
                      >
                        Remove
                      </Button>
                    </Col>
                    <Col span={12} className="text-end">
                      {index === fields.length - 1 && (
                        <Button
                          onClick={handleAddItem}
                          className="border rounded "
                          type="primary"
                        >
                          Add Item
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default PhysicalMarathonDetailsUpdateModal;
