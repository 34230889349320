import React, { useState, useEffect } from 'react';
import { Table, Select, Button, Space, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {
  createUpdateGiftItem,
  getAllGiftItems,
} from '../../Services/ApiServices';
import GiftEditModal from './GiftEditModel';

const { Option } = Select;
const { Search } = Input;

const GiftedItem = () => {
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const fetchGiftItems = async () => {
      try {
        const giftItems = await getAllGiftItems();
        setDataSource(giftItems);
        filterData(giftItems, selectedStatus);
      } catch (error) {
        console.error('Error fetching gift items:', error);
      }
    };

    fetchGiftItems();
  }, [flag]);

  const handleStatusChange = async (value, record) => {
    const newData = dataSource.map((item) =>
      item._id === record._id ? { ...item, status: value } : item
    );
    setDataSource(newData);
    filterData(newData, selectedStatus);
    const payload = {
      giftId: record._id,
      status: value,
    };
    const response = await createUpdateGiftItem(payload);
    if (response.success) {
      setFlag(!flag);
    }
  };

  const handleSearch = (value) => {
    const filteredData = dataSource.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    filterData(filteredData, selectedStatus);
  };

  const handleStatusFilterChange = (value) => {
    setSelectedStatus(value);
    filterData(dataSource, value);
  };

  const filterData = (data, status) => {
    const filteredData = data.filter((item) =>
      status === 'all' ? true : item.status === status
    );
    setFilteredDataSource(filteredData);
  };

  const handleEditGift = (value, record) => {
    setSelectedId(value._id);
    setEditModalVisible(true);
  };

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
      width: 50,
      align: 'center',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (text, record) => (
        <img
          src={record.image}
          alt="Avatar"
          className="rounded-circle"
          style={{ width: 50, height: 50 }}
        />
      ),
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status === 'active' ? 'Active' : 'Inactive'}
          style={{ width: 120 }}
          onChange={(value) => handleStatusChange(value, record)}
        >
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      ),
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="btn btn-none"
            onClick={() => {
              handleEditGift(text, record);
            }}
            icon={<EditOutlined className="text-dark" />}
          />
        </Space>
      ),
      align: 'center',
    },
  ];

  const totalUsers = filteredDataSource.length;

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div className="heading-challenge">Gifted Item List</div>
        <div className="text-center align-self-center count-text">
          Total Users: {totalUsers}
        </div>
      </div>

      <div className="d-flex justify-content-between p-2">
        <Search
          className="custom-search"
          placeholder="Search by user name"
          allowClear
          enterButton={
            <Button style={{ backgroundColor: '#dc0272', color: '#fff' }}>
              Search
            </Button>
          }
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Select
          value={selectedStatus}
          style={{ width: 120, marginLeft: 16 }}
          onChange={handleStatusFilterChange}
        >
          <Option value="all">All</Option>
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      </div>
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={filteredDataSource}
          pagination={{ pageSize: 10 }}
          scroll={{ x: 'max-content' }}
        />
      </div>
      <GiftEditModal
        visible={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        user_id={selectedId}
        onSave={() => setFlag(!flag)}
      />
    </>
  );
};

export default GiftedItem;
