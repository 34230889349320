import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Input, Select, Button, message } from 'antd';
import {
  createUpdateGiftItem,
  getGiftItemDetails,
  uploadImageToAPI,
} from '../../Services/ApiServices';
import Loader from '../Loader/Loader';

const { Option } = Select;

const GiftEditModal = ({ visible, onClose, user_id, onSave }) => {
  const [loading, setLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [hasFetchedImage, setHasFetchedImage] = useState(false);
  const [form] = Form.useForm();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      let payload = { giftId: user_id };
      try {
        setLoading(true);
        const userData = await getGiftItemDetails(payload);
        setCurrentImage(userData?.image);
        setHasFetchedImage(!!userData?.image);
        setFileList([]);
        form.setFieldsValue({
          name: userData?.name,
          amount: userData?.amount,
          status: userData?.status === 'active' ? 'active' : 'inactive',
          updatedAt: formatDateTime(userData?.updatedAt),
          lastLoginAt: formatDateTime(userData?.lastLoginAt),
        });
      } catch (error) {
        console.error('Failed to fetch gift details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (visible && user_id) {
      fetchUserData();
    }
  }, [visible, user_id, form]);

  const formatDateTime = (isoString) => {
    if (!isoString) return '';
    const date = new Date(isoString);
    return date.toISOString().replace('Z', '');
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!');
      return false;
    }
    return true;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && beforeUpload(file)) {
      setFileList([file]);
      setHasFetchedImage(true);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      let imageUrl = currentImage;

      if (fileList.length > 0) {
        const formData = new FormData();
        formData.append('file', fileList[0]);
        const uploadResponse = await uploadImageToAPI(formData);
        if (uploadResponse?.success) {
          imageUrl = uploadResponse.response[0];
        }
      }

      const payload = {
        giftId: user_id,
        name: values.name,
        amount: values.amount,
        status: values.status === 'active' ? 'active' : 'inactive',
        image: imageUrl,
      };

      await createUpdateGiftItem(payload);
      onSave();
      onClose();
    } catch (error) {
      console.error('Failed to update gift item:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      title="Edit Gift"
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSave}
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      {loading && <Loader spinning={true} />}

      <Form form={form} layout="vertical" name="gift_edit_form">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input the name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Amount"
          rules={[{ required: true, message: 'Please input the amount!' }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select the status!' }]}
        >
          <Select>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        </Form.Item>
        <Form.Item name="updatedAt" label="Updated At">
          <Input disabled />
        </Form.Item>
        <Form.Item name="lastLoginAt" label="Last Login At">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="image"
          label="Image"
          rules={[
            { required: !hasFetchedImage, message: 'Please upload an image!' },
          ]}
        >
          <div
            onClick={handleImageClick}
            style={{
              cursor: 'pointer',
              width: '100px',
              height: '100px',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #d9d9d9',
              borderRadius: '4px',
              backgroundColor: '#f5f5f5',
            }}
          >
            {fileList.length > 0 ? (
              <img
                src={URL.createObjectURL(fileList[0])}
                alt="New"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '4px',
                }}
              />
            ) : currentImage ? (
              <img
                src={currentImage}
                alt="Current"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '4px',
                }}
              />
            ) : (
              <span>No Image</span>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleFileChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GiftEditModal;
