import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datetime/css/react-datetime.css';
import './App.css';
import AppMenu from './components/appMenu/AppMenu';
import Login from './components/login/Login';
import Cookies from 'js-cookie';
// import {
//   requestFCMToken,
//   setupOnMessageListener,
// } from './utils/firebaseConfig';

const getToken = () => Cookies.get('token');

const isAuthenticated = () => !!getToken();

const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/" />;
};

const PublicRoute = ({ children }) => {
  return isAuthenticated() ? <Navigate to="/appMenu" /> : children;
};

function App() {
  // const [fcmToken, setFcmToken] = useState(null);

  // useEffect(() => {
  //   const fetchFCMToken = async () => {
  //     try {
  //       const token = await requestFCMToken();
  //       setFcmToken(token);
  //     } catch (err) {
  //       console.error('Error getting FCM Token:', err);
  //     }
  //   };
  //   fetchFCMToken();
  //   setupOnMessageListener();

  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker
  //       .register('/firebase-messaging-sw.js')
  //       .then((registration) => {
  //         console.log('Service Worker registered:', registration);
  //       })
  //       .catch((error) => {
  //         console.error('Service Worker registration failed:', error);
  //       });
  //   }
  // }, []);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/appMenu"
          element={
            <ProtectedRoute>
              <AppMenu />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
