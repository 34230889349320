import React, { useEffect, useState } from 'react';
import {
  Modal,
  Carousel,
  Image,
  Typography,
  Row,
  Col,
  Button,
  Card,
} from 'antd';
import { fetchChallengeDetails } from '../../Services/ApiServices';

const { Title, Text } = Typography;

const DEFAULT_IMAGE_URL = 'default_image_url';

const formatDateTime = (isoString) => {
  if (!isoString) return '-';
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${year}-${month}-${day} ${hours}:${minutes} ${period}`;
};

const ChallengeDetailsModal = ({ visible, onClose, challenge_id }) => {
  const [challengeDetails, setChallengeDetails] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [truncatedDescription, setTruncatedDescription] = useState('');

  useEffect(() => {
    const fetchChallengeData = async () => {
      try {
        const response = await fetchChallengeDetails(challenge_id);
        if (response.success) {
          setChallengeDetails(response.data);
          truncateDescription(response.data.description);
        } else {
          console.error('Failed to fetch challenge details:', response.message);
        }
      } catch (error) {
        console.error('Error fetching challenge details:', error);
      }
    };

    if (visible && challenge_id) {
      fetchChallengeData();
    }
  }, [visible, challenge_id]);

  const truncateDescription = (description) => {
    const maxLength = 1000;
    setTruncatedDescription(
      description.length > maxLength
        ? description.substring(0, maxLength) + '...'
        : description
    );
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  if (!challengeDetails) {
    return null;
  }

  const {
    challenge_name,
    challenge_image,
    challenge_type,
    description,
    challenge_rule,
    challenge_limit,
    points,
    start_time,
    end_time,
    time_limit,
    in_challenge_rewards,
    badge,
    discount,
    original_price,
    enrolled_user,
    challengeCode,
    clip,
    sub_challenges,
  } = challengeDetails;

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      width={900}
      style={{ maxHeight: '75vh', overflowY: 'auto', overflowX: 'hidden' }}
    >
      <Title
        level={3}
        className="text-center mt-3"
        style={{ color: '#dc0272' }}
      >
        {challenge_name}
      </Title>
      <div className="p-2">
        <Row gutter={[16, 16]}>
          <Col md={12} className="d-flex justify-content-center">
            <Carousel
              autoplay
              style={{
                textAlign: 'center',
                background: '#333',
                height: '300px',
              }}
              dotStyle={{ backgroundColor: '#fff', borderColor: '#fff' }}
            >
              {(challenge_image && challenge_image.length > 0
                ? challenge_image
                : [DEFAULT_IMAGE_URL]
              ).map((imageUrl, index) => (
                <div key={index}>
                  <Image
                    src={imageUrl}
                    alt={`Challenge Image ${index + 1}`}
                    preview={false}
                    width="100%"
                    height="100%"
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              ))}
            </Carousel>
          </Col>
          <Col md={12}>
            <Text>{expanded ? description : truncatedDescription}</Text>
            {description.length > 1000 && (
              <Button
                type="link"
                style={{ fontSize: 10 }}
                className="text-danger"
                onClick={toggleExpanded}
              >
                {expanded ? 'See less' : 'See more'}
              </Button>
            )}
          </Col>
        </Row>
        {clip && (
          <Card className="mt-4 border-0">
            <Title level={4} style={{ color: '#dc0272' }}>
              Challenge Video Clip
            </Title>
            <video width="100%" height="auto" controls>
              <source src={clip} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Card>
        )}
        {challenge_rule && (
          <Card className="mt-4">
            <Title level={4} style={{ color: '#dc0272' }}>
              Challenge Rules
            </Title>
            <ul>
              {challenge_rule.rule1 && <li>{challenge_rule.rule1}</li>}
              {challenge_rule.rule2 && <li>{challenge_rule.rule2}</li>}
            </ul>
            <Title level={4} style={{ color: '#dc0272' }}>
              Challenge Rewards
            </Title>
            <ul>
              <li>{in_challenge_rewards}</li>
            </ul>
            <Title level={4} style={{ color: '#dc0272' }}>
              Badge
            </Title>
            {badge && (
              <div>
                <ul>
                  <li>
                    Badge name : {badge.badge_name}
                    <br />
                    {badge.badge_description}
                  </li>
                </ul>
                <Image
                  src={
                    badge.badge_image && badge.badge_image.length > 0
                      ? badge.badge_image[0]
                      : DEFAULT_IMAGE_URL
                  }
                  width={100}
                  className="border border-dark rounded"
                />
              </div>
            )}
            <Row gutter={[32]} className="p-2">
              <Col xs={24} md={12}>
                <Title level={5}>
                  Challenge Type:{' '}
                  <span className="text-secondary">{challenge_type}</span>
                </Title>
                <Title level={5}>
                  Start Time:{' '}
                  <span className="text-secondary">
                    {formatDateTime(start_time)}
                  </span>
                </Title>
                <Title level={5}>
                  Time Limit:{' '}
                  <span className="text-secondary">{time_limit}</span>
                </Title>
                <Title level={5}>
                  Original Price:{' '}
                  <span className="text-secondary">{original_price}</span>
                </Title>
                <Title level={5}>
                  Enrolled User:{' '}
                  <span className="text-secondary">{enrolled_user}</span>
                </Title>
              </Col>
              <Col xs={24} md={12}>
                <Title level={5}>
                  Challenge Limit:{' '}
                  <span className="text-secondary">{challenge_limit}</span>
                </Title>
                <Title level={5}>
                  End Time:{' '}
                  <span className="text-secondary">
                    {formatDateTime(end_time)}
                  </span>
                </Title>
                <Title level={5}>
                  Points: <span className="text-secondary">{points}</span>
                </Title>
                <Title level={5}>
                  Discount: <span className="text-secondary">{discount}</span>
                </Title>
                <Title level={5}>
                  Challenge Code:{' '}
                  <span className="text-secondary">{challengeCode}</span>
                </Title>
              </Col>
            </Row>
          </Card>
        )}
        {sub_challenges && sub_challenges.length > 0 && (
          <Card className="mt-4">
            <Title level={4} style={{ color: '#dc0272' }}>
              Sub Challenges
            </Title>
            <ul>
              {sub_challenges.map((subChallenge) => (
                <li key={subChallenge._id} className="mb-2">
                  <strong>{subChallenge.name}</strong>
                  <p>{subChallenge.description}</p>
                  <Text>Points: {subChallenge.points}</Text>
                  <br />
                  <Text>Time Limit: {subChallenge.time_limit}</Text>
                </li>
              ))}
            </ul>
          </Card>
        )}
      </div>
    </Modal>
  );
};

export default ChallengeDetailsModal;
