import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import { Form, Button, Row, Col } from 'react-bootstrap';
import {
  createOrUpdateChallenge,
  uploadImageToAPI,
  fetchUsersList,
  fetchChallengeDetails,
} from '../../Services/ApiServices';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import MapChallenge from '../challenges/MapChallenge';

const ChallangeDetailsUpdate = ({ visible, onClose, challenge_id }) => {
  const [challengeDetails, setChallengeDetails] = useState({
    challenge_name: '',
    challenge_image: [],
    challange_type: '',
    challenge_limit: '',
    description: '',
    start_time: '',
    end_time: '',
    time_limit: '',
    points: '',
    in_challenge_rewards: '',
    gifted_to: [],
    challenge_rule: {
      rule1: '',
      rule2: '',
    },
    clip: [],
    original_price: '',
    discount: '',
    challengeCode: '',
    sub_challenges: [
      {
        name: '',
        points: '',
        time_limit: '',
        description: '',
        latitude: '',
        longitude: '',
      },
    ],
  });
  const [users, setUsers] = useState([]);
  const [selectedChallengeType, setSelectedChallengeType] = useState('');
  const [imageResponse, setImageResponse] = useState([]);
  const [clipResponse, setClipResponse] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedUserId, setSelectedUserId] = useState([]);
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleMapClick = () => {
    setMapModalVisible(true);
  };

  const handleCloseMapModal = () => {
    setMapModalVisible(false);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const selectedUser = users.find(
      (user) => user.first_name + ' ' + user.last_name === value
    );
    if (selectedUser) {
      setChallengeDetails({
        ...challengeDetails,
        gifted_to: [selectedUser._id],
      });
      setSelectedUserId(selectedUser._id);
    }
  };

  const handleSelectChange = (value) => {
    setSelectedUserId(value);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userList = await fetchUsersList();
        setUsers(userList);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleLocationSelect = (lat, lng, index) => {
    const updatedSubchallenges = [...challengeDetails.sub_challenges];
    updatedSubchallenges[index].latitude = lat.toString();
    updatedSubchallenges[index].longitude = lng.toString();
    setChallengeDetails({
      ...challengeDetails,
      sub_challenges: updatedSubchallenges,
    });
  };

  const handleChallengeTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedChallengeType(selectedType);

    if (selectedType === 'unlimited') {
      setChallengeDetails({
        ...challengeDetails,
        challange_type: selectedType,
        challenge_limit: '',
      });
    } else {
      setChallengeDetails({
        ...challengeDetails,
        challange_type: selectedType,
      });
    }
  };

  useEffect(() => {
    setChallengeDetails((prevDetails) => ({
      ...prevDetails,
      challenge_limit:
        selectedChallengeType === '' ? '' : challengeDetails.challenge_limit,
    }));
  }, [selectedChallengeType]);

  const handleChangeRule = (ruleNumber, value) => {
    setChallengeDetails({
      ...challengeDetails,
      challenge_rule: {
        ...challengeDetails.challenge_rule,
        [`rule${ruleNumber}`]: value,
      },
    });
  };

  const handleChange = (index, fieldName, e) => {
    const updatedSubchallenges = [...challengeDetails.sub_challenges];
    updatedSubchallenges[index][fieldName] = e.target.value;
    setChallengeDetails({
      ...challengeDetails,
      sub_challenges: updatedSubchallenges,
    });
  };

  const handleAddField = () => {
    setChallengeDetails({
      ...challengeDetails,
      sub_challenges: [
        ...challengeDetails.sub_challenges,
        {
          name: '',
          points: '',
          time_limit: '',
          description: '',
          latitude: '',
          longitude: '',
        },
      ],
    });
  };

  const handleRemoveField = (index) => {
    const updatedSubchallenges = [...challengeDetails.sub_challenges];
    updatedSubchallenges.splice(index, 1);
    setChallengeDetails({
      ...challengeDetails,
      sub_challenges: updatedSubchallenges,
    });
  };

  const handleChangeFile = async (e, type) => {
    const files = [...e.target.files];
    const formData = new FormData();

    files.forEach((file) => formData.append('file', file));

    try {
      let response;
      if (type === 'image') {
        response = await uploadImageToAPI(formData);
        if (response) {
          setImageResponse(response.response);
        }
      } else if (type === 'clip') {
        response = await uploadImageToAPI(formData);
        if (response) {
          setClipResponse(response.response);
        }
      }
    } catch (error) {
      toast.error(`Error uploading ${type}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!challengeDetails.start_time || !challengeDetails.end_time) {
      toast.error('Please select start and end time');
      return;
    }

    try {
      const formattedStartTime = challengeDetails?.start_time
        ? new Date(challengeDetails.start_time).toISOString()
        : new Date().toISOString();
      const formattedEndTime = challengeDetails?.end_time
        ? new Date(challengeDetails.end_time).toISOString()
        : new Date().toISOString();

      const updatedSubChallenges = challengeDetails.sub_challenges.map(
        (subChallenge) => ({
          ...subChallenge,
          latitude: subChallenge.latitude.toString(),
          longitude: subChallenge.longitude.toString(),
        })
      );

      const updatedChallengeDetails = {
        ...challengeDetails,
        gifted_to: selectedUserId,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        challenge_image: imageResponse,
        clip: clipResponse,
        sub_challenges: updatedSubChallenges,
      };
      const response = await createOrUpdateChallenge(updatedChallengeDetails);
      if (response.success === true) {
        toast.success(response.message);
        navigate('/');

        setChallengeDetails({
          challenge_name: '',
          challenge_image: [],
          challange_type: '',
          challenge_limit: '',
          description: '',
          start_time: '',
          end_time: '',
          time_limit: '',
          points: '',
          in_challenge_rewards: '',
          gifted_to: [],
          challenge_rule: {
            rule1: '',
            rule2: '',
          },
          clip: [],
          original_price: '',
          discount: '',
          challengeCode: '',
          sub_challenges: [
            {
              name: '',
              points: '',
              time_limit: '',
              description: '',
              latitude: '',
              longitude: '',
            },
          ],
        });
      } else {
        handleSubmissionError(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const fetchChallengeData = async () => {
      try {
        const response = await fetchChallengeDetails(challenge_id);
        if (response.success) {
          setChallengeDetails({
            ...response.data,
            start_time: moment(response.data.start_time),
            end_time: moment(response.data.end_time),
          });
          setSelectedChallengeType(response.data.challenge_type);
        } else {
          console.error('Failed to fetch challenge details:', response.message);
        }
      } catch (error) {
        console.error('Error fetching challenge details:', error);
      }
    };

    if (challenge_id) {
      fetchChallengeData();
    }
  }, [challenge_id]);

  const handleSubmissionError = (errorMessage) => {
    if (errorMessage.includes('sub_challenges')) {
      toast.error('Sub challenge field cannot be empty');
    } else {
      toast.error('An error occurred: ' + errorMessage);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      width={900}
      style={{ top: 0, maxHeight: 'calc(100vh - 120px)', overflow: 'auto' }}
    >
      <Row className="justify-content-md-center">
        <Col lg={10} sm={12} md={10} xs={12}>
          <div className="card mt-2 mx-auto p-4 ">
            <div className="card-header text-center my-0">
              <h3 style={{ color: '#dc0272' }}>Update Challenges</h3>
            </div>
            <div className="card-body">
              <Form>
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="challengeName">
                      <Form.Label>Add Challenge Name</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-2"
                        value={challengeDetails.challenge_name}
                        onChange={(e) =>
                          setChallengeDetails({
                            ...challengeDetails,
                            challenge_name: e.target.value,
                          })
                        }
                        placeholder="Please enter challenge Name"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Challange Image</Form.Label>
                      <Form.Control
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={(e) => handleChangeFile(e, 'image')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="challengeType">
                      <Form.Label>Challenge Type</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-2"
                        value={selectedChallengeType}
                        onChange={handleChallengeTypeChange}
                      >
                        <option value="">Select</option>
                        <option value="limited">Limited</option>
                        <option value="unlimited">Unlimited</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="challengeName">
                      <Form.Label>Challange Limit</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-2"
                        value={challengeDetails.challenge_limit}
                        onChange={(e) =>
                          setChallengeDetails({
                            ...challengeDetails,
                            challenge_limit: e.target.value,
                          })
                        }
                        placeholder="Please enter challenge Limit"
                        // disabled={selectedChallengeType === 'unlimited'}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    className="mb-2"
                    rows={3}
                    placeholder="Description"
                    value={challengeDetails.description}
                    onChange={(e) =>
                      setChallengeDetails({
                        ...challengeDetails,
                        description: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="start_time">
                      <Form.Label>Start Time</Form.Label>
                      <DateTime
                        className="mb-2"
                        value={challengeDetails.start_time}
                        onChange={(date) =>
                          setChallengeDetails({
                            ...challengeDetails,
                            start_time: date,
                          })
                        }
                        inputProps={{
                          placeholder: 'Please enter start time',
                        }}
                        isValidDate={(currentDate) => {
                          const startOfToday = moment().startOf('day');
                          return currentDate.isSameOrAfter(startOfToday);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="end_time">
                      <Form.Label>End Time</Form.Label>
                      <DateTime
                        className="mb-2"
                        value={challengeDetails.end_time}
                        onChange={(date) =>
                          setChallengeDetails({
                            ...challengeDetails,
                            end_time: date,
                          })
                        }
                        inputProps={{ placeholder: 'Please enter end time' }}
                        isValidDate={(currentDate) => {
                          const startDateTime = moment(
                            challengeDetails.start_time
                          );
                          return (
                            currentDate.isSameOrAfter(startDateTime) ||
                            currentDate.isSame(startDateTime, 'day')
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="days">
                      <Form.Label>Time Limit</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-2 "
                        value={challengeDetails.time_limit}
                        onChange={(e) =>
                          setChallengeDetails({
                            ...challengeDetails,
                            time_limit: e.target.value,
                          })
                        }
                        placeholder="Please enter Time Limit"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="points">
                      <Form.Label>Points</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-2"
                        value={challengeDetails.points}
                        onChange={(e) =>
                          setChallengeDetails({
                            ...challengeDetails,
                            points: e.target.value,
                          })
                        }
                        placeholder="Please enter points"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <Form.Group controlId="start_time">
                      <Form.Label>In Challange Rewards</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-2 "
                        value={challengeDetails.in_challenge_rewards}
                        onChange={(e) =>
                          setChallengeDetails({
                            ...challengeDetails,
                            in_challenge_rewards: e.target.value,
                          })
                        }
                        placeholder="Please enter in challange rewards"
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="points">
                      <Form.Label>Gifted To</Form.Label>
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Search by name"
                        optionFilterProp="children"
                        value={selectedUserId}
                        onChange={handleSelectChange}
                        onSearch={handleSearch}
                        filterOption={(input, option) => {
                          const fullName = `${option.first_name} ${option.last_name}`;
                          const searchTerms = input.toLowerCase().split(' ');
                          return searchTerms.every(
                            (term) =>
                              fullName.toLowerCase().includes(term) ||
                              option.first_name.toLowerCase().includes(term) ||
                              option.last_name.toLowerCase().includes(term)
                          );
                        }}
                      >
                        {users
                          .filter(
                            (user) =>
                              user.first_name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase()) ||
                              user.last_name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                          )
                          .map((user) => (
                            <Option
                              key={user._id}
                              value={user._id}
                              first_name={user.first_name}
                              last_name={user.last_name}
                            >
                              {user.first_name} {user.last_name}
                            </Option>
                          ))}
                      </Select>
                    
                    </Form.Group>
                  </Col> */}
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="points">
                      <Form.Label>Clip </Form.Label>
                      <Form.Control
                        type="file"
                        accept="video/*"
                        onChange={(e) => handleChangeFile(e, 'clip')}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="start_time">
                      <Form.Label>Original Price</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-2 "
                        value={challengeDetails.original_price}
                        onChange={(e) =>
                          setChallengeDetails({
                            ...challengeDetails,
                            original_price: e.target.value,
                          })
                        }
                        placeholder="Please enter Original Price"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Form.Label>Challange Rule</Form.Label>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="challengeName">
                      <Form.Control
                        type="text"
                        value={challengeDetails.challenge_rule?.rule1}
                        onChange={(e) => handleChangeRule(1, e.target.value)}
                        placeholder="Rule 1"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Control
                        type="text"
                        value={challengeDetails.challenge_rule?.rule2}
                        onChange={(e) => handleChangeRule(2, e.target.value)}
                        placeholder="Rule 2"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="points">
                      <Form.Label>Discount </Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-2"
                        value={challengeDetails.discount}
                        onChange={(e) =>
                          setChallengeDetails({
                            ...challengeDetails,
                            discount: e.target.value,
                          })
                        }
                        placeholder="Please enter Discount"
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={12} md={6} lg={6}>
                    <Form.Group controlId="points">
                      <Form.Label>Challenge Code</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-2"
                        value={challengeDetails.challengeCode}
                        onChange={(e) =>
                          setChallengeDetails({
                            ...challengeDetails,
                            challengeCode: e.target.value,
                          })
                        }
                        placeholder="Please enter challengeCode"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-2">
                  <h3 className="mt-4 mb-4">Sub Challenge</h3>
                  {challengeDetails?.sub_challenges?.map((field, index) => (
                    <div key={index}>
                      <Form.Control
                        type="text"
                        className="mb-2"
                        placeholder="Challenge Name"
                        value={field.name}
                        onChange={(e) => handleChange(index, 'name', e)}
                      />

                      <Form.Control
                        as="textarea"
                        className="mb-2"
                        rows={3}
                        placeholder="Description"
                        value={field.description}
                        onChange={(e) => handleChange(index, 'description', e)}
                      />
                      <Row>
                        <Col sm={12} md={4} lg={4} className="mb-2">
                          <Form.Control
                            type="text"
                            placeholder="Time Limits"
                            value={field.time_limit}
                            onChange={(e) =>
                              handleChange(index, 'time_limit', e)
                            }
                          />
                        </Col>
                        <Col sm={12} md={4} lg={4} className="mb-2">
                          <Form.Control
                            type="text"
                            placeholder="Points"
                            value={field.points}
                            onChange={(e) => handleChange(index, 'points', e)}
                          />
                        </Col>
                        <Col sm={12} md={4} lg={4} className="mb-2">
                          <Button onClick={handleMapClick}>Open Map</Button>
                        </Col>
                      </Row>

                      <Modal
                        title="Select location on Map"
                        open={mapModalVisible}
                        onCancel={handleCloseMapModal}
                        footer={null}
                      >
                        <MapChallenge
                          handleLocationSelect={(lat, lng) =>
                            handleLocationSelect(lat, lng, index)
                          }
                        />
                      </Modal>

                      <div className="d-flex justify-content-between mb-5">
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveField(index)}
                          className="me-2"
                          disabled={
                            challengeDetails.sub_challenges.length === 1
                          }
                        >
                          Remove
                        </Button>
                        {index ===
                          challengeDetails.sub_challenges.length - 1 && (
                          <Button variant="success" onClick={handleAddField}>
                            Add
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </Form.Group>

                <Button
                  variant="success"
                  className="btn-block p-2 fs-6 col-12 fw-bold"
                  onClick={(e) => handleSubmit(e)}
                >
                  Add Challenge
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ChallangeDetailsUpdate;
