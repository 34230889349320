import React, { useState } from 'react';
import { Card, Col, Image, Modal, Row, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import DefaultAvatar from '../../Assets/Images/avatar.jpg';
import { removeEnrolledUserFromMarathon } from '../../Services/ApiServices';

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

const UserCard = ({ user, marathonId, onUserDeleted }) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const showDeleteModal = () => setDeleteModalVisible(true);
  const handleCancel = () => setDeleteModalVisible(false);

  const handleDelete = async () => {
    try {
      const response = await removeEnrolledUserFromMarathon(
        user._id,
        marathonId
      );
      if (response.status === 200) {
        notification.success({
          message: 'User Deleted',
          description: `${capitalizeFirstLetter(
            user.first_name
          )} ${capitalizeFirstLetter(
            user.last_name
          )} has been successfully deleted.`,
        });
        setDeleteModalVisible(false);
        onUserDeleted();
      } else {
        throw new Error('Failed to remove user');
      }
    } catch (error) {
      console.error('Failed to delete user:', error.message);
      notification.error({
        message: 'Deletion Failed',
        description: `Failed to delete ${capitalizeFirstLetter(
          user.first_name
        )} ${capitalizeFirstLetter(user.last_name)}. Please try again.`,
      });
    } finally {
      setDeleteModalVisible(false);
    }
  };

  const userDetails = [
    { label: 'Email', value: user.email_id },
    {
      label: 'Gender',
      value:
        user.gender === 1 ? 'Male' : user.gender === 2 ? 'Female' : 'Other',
    },
    { label: 'Nationality', value: user.nationality },
    {
      label: 'Registered At',
      value: new Date(user.createdAt).toLocaleDateString(),
    },
    { label: 'Friend Request', value: user.friendRequest.length },
    { label: 'Points earned', value: user.points_earned },
  ];

  return (
    <>
      <Card
        title={`${capitalizeFirstLetter(
          user.first_name
        )} ${capitalizeFirstLetter(user.last_name)}`}
        extra={
          <DeleteOutlined
            onClick={showDeleteModal}
            style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }}
          />
        }
        className="mb-3 p-0"
      >
        <Row gutter={[16, 16]} align="middle">
          <Col
            xs={24}
            sm={24}
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <Image
              src={user.image || DefaultAvatar}
              width={100}
              height={100}
              alt="User Image"
              className="border rounded-circle"
            />
          </Col>
          <Col xs={24} sm={24} md={18}>
            {userDetails.map((detail, index) => (
              <Row gutter={16} key={index}>
                <Col span={12}>
                  <strong>{detail.label}:</strong>
                </Col>
                <Col span={12}>{detail.value}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Card>
      <Modal
        title={`Delete ${capitalizeFirstLetter(
          user.first_name
        )} ${capitalizeFirstLetter(user.last_name)}?`}
        open={deleteModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        <p>Are you sure you want to delete this user?</p>
      </Modal>
    </>
  );
};

export default UserCard;
