import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Button, Space } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import {
  fetchUsersList,
  updateProfileStatus,
} from '../../Services/ApiServices';
import UserDetails from './UserDetails';
import UserEditModal from './UserEditModal';
import DefaultAavtar from '../../Assets/Images/avatar.jpg';
import Loader from '../Loader/Loader';

const { Search } = Input;
const { Option } = Select;

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [loading, setLoading] = useState(true);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchUsersList();
      const usersWithKey = data.users.map((user) => ({
        ...user,
        key: user._id,
      }));
      setUsers(usersWithKey);
      setFilteredUsers(usersWithKey);
      setTotalUsers(usersWithKey.length);
    } catch (error) {
      console.error('Error fetching user data: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (value, record) => {
    setStatusChangeLoading(true);
    try {
      await updateProfileStatus(record._id, value === 'active' ? 1 : 2);
      const updatedUsers = users.map((user) =>
        user._id === record._id
          ? { ...user, status: value === 'active' ? 1 : 2 }
          : user
      );

      setUsers(updatedUsers);

      let updatedFilteredUsers = updatedUsers;
      if (selectedStatus !== 'all') {
        updatedFilteredUsers = updatedUsers.filter((user) =>
          selectedStatus === 'active' ? user.status === 1 : user.status === 2
        );
      }

      setFilteredUsers(updatedFilteredUsers);

      setTotalUsers(updatedFilteredUsers.length);
    } catch (error) {
      console.error('Error updating profile status: ', error);
    } finally {
      setStatusChangeLoading(false);
    }
  };

  const handleSearch = (value) => {
    if (users.length) {
      const filteredData = users.filter(
        (user) =>
          user?.first_name?.toLowerCase().includes(value.toLowerCase()) ||
          user?.last_name?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredUsers(filteredData);
      setTotalUsers(filteredData.length);
    }
  };

  const handleStatusFilterChange = (value) => {
    setSelectedStatus(value);
    let filteredData = users;
    if (value !== 'all') {
      filteredData = users.filter((user) =>
        value === 'active' ? user.status === 1 : user.status === 2
      );
    }
    setFilteredUsers(filteredData);
    setTotalUsers(filteredData.length);
  };

  const handleMenuClick = (record, action) => {
    const user_id = record._id;
    if (action.key === 'view') {
      setSelectedUserId(user_id);
      setModalVisible(true);
    } else if (action.key === 'edit') {
      setSelectedUserId(user_id);
      setEditModalVisible(true);
    }
  };

  const handleSaveUser = async (updatedUser) => {
    try {
      const updatedUsers = users.map((user) =>
        user._id === selectedUserId ? { ...user, ...updatedUser } : user
      );
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
      await fetchData();
    } catch (error) {
      console.error('Error updating user: ', error);
    }
  };

  const formatDateTime = (isoString) => {
    if (!isoString) return '-';
    const date = new Date(isoString);
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return formattedDate;
  };

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
      width: 50,
      align: 'center',
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text, record, index) => (
        <img
          src={record.avatar || DefaultAavtar}
          alt="Avatar"
          style={{ width: 50, borderRadius: '50%' }}
        />
      ),
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) =>
        `${record.first_name} ${record.last_name}`,
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'email_id',
      key: 'email_id',
      align: 'center',
      render: (text, record, index) =>
        record.email_id ? record.email_id : '-',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      render: (text, record, index) =>
        record.gender === 1 ? 'Male' : 'Female',
      align: 'center',
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLoginAt',
      key: 'lastLoginAt',
      render: (text, record, index) => formatDateTime(record.lastLoginAt),
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record, index) => (
        <Select
          value={status === 1 ? 'active' : 'inactive'}
          style={{ width: 120 }}
          onChange={(value) => handleStatusChange(value, record)}
          loading={statusChangeLoading}
        >
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      ),
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <Space size="middle">
          <Button
            className="btn btn-none"
            onClick={() => handleMenuClick(record, { key: 'view' })}
            icon={<EyeOutlined className="text-dark" />}
          />
          <Button
            className="btn btn-none"
            onClick={() => handleMenuClick(record, { key: 'edit' })}
            icon={<EditOutlined className="text-dark" />}
          />
        </Space>
      ),
      align: 'center',
    },
  ];

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div className="table-responsive">
      {loading || statusChangeLoading ? <Loader spinning={true} /> : null}

      <div className="d-flex justify-content-between mb-3">
        <div className="heading-challenge">User List</div>
        <div className="text-center align-self-center count-text">
          Total Users: {totalUsers}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-md-10 col-sm-12">
          <Search
            className="custom-search mb-2"
            placeholder="Search by user name"
            allowClear
            enterButton={
              <Button style={{ backgroundColor: '#dc0272', color: '#fff' }}>
                Search
              </Button>
            }
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12">
          <Select
            value={selectedStatus}
            className="w-100"
            onChange={handleStatusFilterChange}
          >
            <Option value="all">All</Option>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={filteredUsers}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange}
      />
      <UserDetails
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        user_id={selectedUserId}
      />
      <UserEditModal
        visible={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        user_id={selectedUserId}
        onSave={handleSaveUser}
      />
    </div>
  );
};

export default Users;
