import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
// import { retrieveToken } from '../utils/firebaseConfig';
import { makeJsonApiRequest, makeJsonFormDataApiRequest } from './ApiRequest';

// const BASE_URL = 'https://api.doctcares.in/v1';
const BASE_URL = 'https://api2.doctcares.in/v1';

const token = Cookies.get('token');

// utils.js
export const formatDateTime = (isoString) => {
  if (!isoString) return '-';
  const date = new Date(isoString);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const setTokenInCookies = (token, userDetails, expiryDays = 7) => {
  try {
    Cookies.set('token', token, { expires: expiryDays });
    Cookies.set('user_details', JSON.stringify(userDetails), {
      expires: expiryDays,
    });
  } catch (error) {
    console.error('Error setting token in cookies:', error);
  }
};

// export const createOrUpdateChallenge = async (challengeData) => {
//   const cleanData = {
//     ...challengeData,
//     challenge_id: challengeData.challenge_id || challengeData?._id,
//     challenge_limit: challengeData.challenge_limit
//       ? Number(challengeData.challenge_limit)
//       : undefined,
//   };

//   const { _id, createdAt, updatedAt, __v, enrolled_user, ...payload } =
//     cleanData;

//   try {
//     const body = JSON.stringify(payload);
//     const response = await fetch(`${BASE_URL}/create-update-challenge`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: token,
//       },
//       body: JSON.stringify(payload),
//     });

//     console.log('response:11111 ', response);
//     return await response.json();
//   } catch (error) {
//     throw new Error(`Failed to create or update challenge: ${error.message}`);
//   }
// };

export const createOrUpdateChallenge = async (challengeData) => {
  const cleanData = {
    ...challengeData,
    challenge_id: challengeData.challenge_id || challengeData?._id,
    challenge_limit: challengeData.challenge_limit
      ? Number(challengeData.challenge_limit)
      : undefined,
  };

  const { _id, createdAt, updatedAt, __v, enrolled_user, ...payload } =
    cleanData;

  try {
    const response = await axios.post(
      `${BASE_URL}/create-update-challenge`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    );
    console.log('response: ', response);

    console.log('response:', response);
    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to create or update challenge: ${
        error.response?.data?.message || error.message
      }`
    );
  }
};

export const uploadImageToAPI = async (formData) => {
  try {
    const response = await makeJsonFormDataApiRequest(
      'post',
      `${BASE_URL}/upload-images`,
      formData
    );

    return response.data;
  } catch (error) {
    console.error('Error uploading image to API:', error);
    throw error;
  }
};

export const loginToAPI = async (payload) => {
  // const deviceToken = await retrieveToken();

  // if (!deviceToken) {
  //   console.error('Failed to retrieve device token.');
  //   return;
  // }

  // payload = {
  //   ...payload,
  //   deviceToken: deviceToken,
  // };

  payload = {
    ...payload,
    deviceToken: '1234567890',
  };

  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/admin-sign-in`,
      payload
    );

    if (response.status === 200 && response.data.success) {
      const token = response.data.response.token;
      const userDetails = response.data.response.user_details;
      setTokenInCookies(token, userDetails);
    }

    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const fetchUsersList = async () => {
  try {
    const response = await makeJsonApiRequest(
      'get',
      `${BASE_URL}/get-users-list`,
      {}
    );
    if (response.status === 200) {
      return response.data.response;
    } else {
      console.error('Failed to fetch users:', response.message);
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Error fetching users:', error.message);
    throw error;
  }
};

export const fetchPrivacyPolicy = async () => {
  try {
    const response = await makeJsonApiRequest(
      'get',
      `${BASE_URL}/get-privacy-policy`,
      {}
    );
    return response.data.response;
  } catch (error) {
    console.error('Error fetching privacy policy:', error);
    throw error;
  }
};

export const updatePrivacyPolicy = async (policyData) => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/add-update-privacy-policy`,
      policyData
    );
    return response.data;
  } catch (error) {
    console.error('Error updating privacy policy:', error);
    throw error;
  }
};

export const createUpdateTermsConditions = async (termsCondition) => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/add-update-privacy-policy`,
      termsCondition
    );
    return response.data;
  } catch (error) {
    console.error('Error updating privacy policy:', error);
    throw error;
  }
};

export const fetchTermsCondition = async () => {
  try {
    const response = await makeJsonApiRequest(
      'get',
      `${BASE_URL}/get-terms-and-condition`,
      {}
    );
    return response.data.response;
  } catch (error) {
    console.error('Error fetching terms and conditions:', error);
    throw error;
  }
};

export const fetchChallengeList = async () => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/get-all-challenge-list`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching challenge list:', error);
    return null;
  }
};

export const fetchChallengeDetails = async (challenge_id) => {
  try {
    const response = await makeJsonApiRequest(
      'get',
      `${BASE_URL}/challenge-details?chellenge_id=${challenge_id}`,
      {}
    );
    const { data } = response;
    return data;
  } catch (error) {
    console.error('Error fetching challenge details:', error);
    return { success: false, message: 'Failed to fetch challenge details' };
  }
};

export const updateProfileStatus = async (userId, status) => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/update-profile`,
      {
        userId,
        status,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserDetails = async (user_id) => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/get-user-details-by-admin`,
      {
        userId: user_id,
      }
    );
    return response.data.response;
  } catch (error) {
    console.error('Error fetching user details: ', error);
    throw error;
  }
};

export const updateUserProfile = async (payload) => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/update-profile`,
      payload
    );
    toast.success(response.data.message);

    return response.data;
  } catch (error) {
    console.error('Failed to update user profile:', error);
    throw error;
  }
};

export const createPhysicalMarathon = async (formData) => {
  formData._id = formData?._id;
  // delete formData?._id;
  delete formData?.createdAt;
  delete formData?.updatedAt;
  delete formData?.__v;
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/create-physical-marathon`,
      formData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMarathonsList = async (limit, searchName, page) => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/get-all-physical-marathon`,
      {
        limit,
        searchName,
        page,
      }
    );
    if (response.status === 200) {
      return response.data.response;
    } else {
      throw new Error('Failed to fetch marathons');
    }
  } catch (error) {
    console.error('Error fetching marathons:', error);
    throw error;
  }
};

export const fetchMarathonDetails = async (marathonId) => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/get-physical-marathon-details`,
      {
        _id: marathonId,
      }
    );
    return response.data.response;
  } catch (error) {
    console.error('Error fetching marathon details:', error);
    throw error;
  }
};

// export const removeEnrolledUserFromMarathon = async (userId, marathonId) => {
//   const payload = {
//     userId,
//     marathonId
//   }
//   const url = `${BASE_URL}/remove-enroll-user-marathon`;

//   const response = await
//    fetch(url, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(requestBody),
//   });

//   if (!response.ok) {
//     const errorMessage = `An error occurred: ${response.statusText}`;
//     throw new Error(errorMessage);
//   }

//   return response.json();
// };

export const removeEnrolledUserFromMarathon = async (userId, marathonId) => {
  const payload = {
    userId,
    marathonId,
  };
  const response = await makeJsonApiRequest(
    'post',
    `${BASE_URL}/remove-enroll-user-marathon`,
    payload
  );
  if (response.success) {
    return response.message;
  } else {
    return response.message;
  }
  // if (!response.ok) {
  //   const errorMessage = `An error occurred: ${response.statusText}`;
  //   throw new Error(errorMessage);
  // }

  // return response.json();
};

export const createUpdateGiftItem = async (giftData) => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/create-update-gift-items`,
      giftData
    );
    return response.data;
  } catch (error) {
    console.error('Error creating/updating gift item:', error);
    throw error;
  }
};

export const getAllGiftItems = async () => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/get-all-gift-items`,
      {}
    );
    const { data } = response;

    if (data.success) {
      return data.response;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    console.error('Failed to fetch gift items:', error.message);
    throw error;
  }
};

export const getGiftItemDetails = async (userId) => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/get-gift-by-id`,
      userId
    );
    const { data } = response;

    if (data.success) {
      return data.response;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    console.error('Failed to fetch gift item:', error.message);
    throw error;
  }
};

export const fetchAdvertisementUrl = async () => {
  try {
    const { status, data } = await makeJsonApiRequest(
      'get',
      `${BASE_URL}/get-advertisment-popup`,
      {}
    );
    if (status === 200 && data.success && data.response) {
      return data.response;
    } else {
      throw new Error('Invalid response structure');
    }
  } catch (error) {
    throw error;
  }
};

export const updateAdvertisementPopup = async (id, advertismentUrl) => {
  try {
    const response = await fetch(`${BASE_URL}/update-advertisment-popup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        _id: id,
        advertismentUrl,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update advertisement popup.');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating advertisement popup:', error);
    throw error;
  }
};

// export const fetchMessages = async (userId) => {
//   console.log('userId', userId);
//   try {
//     const response = await makeJsonApiRequest(
//       'post',
//       `${BASE_URL}/get-all-support-message`,
//       { userId: userId }
//     );
//     console.log('response111', response);
//     if (!response.ok) {
//       throw new Error('Network response was not ok!');
//     }

//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error('Failed to fetch message : ', error);
//     throw error;
//   }
// };

export const fetchMessages = async (userId) => {
  try {
    const response = await makeJsonApiRequest(
      'post',
      `${BASE_URL}/get-all-support-message`,
      { userId: userId }
    );
    console.log('response: ', response);
    return response.data;
  } catch (error) {
    throw error;
  }
};
