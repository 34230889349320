import React, { useState, useEffect } from 'react';
import { Upload, Button, message, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
  fetchAdvertisementUrl,
  uploadImageToAPI,
  updateAdvertisementPopup,
} from '../../Services/ApiServices';

const { Dragger } = Upload;

function AdvertisementPost() {
  const [fileList, setFileList] = useState([]);
  const [advertisementUrl, setAdvertisementUrl] = useState('');
  const [advertisementId, setAdvertisementId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        const response = await fetchAdvertisementUrl();

        if (response && response.advertismentUrl) {
          setAdvertisementUrl(response.advertismentUrl);
          setAdvertisementId(response._id);
        } else {
          message.error('Failed to fetch advertisement image URL');
        }
      } catch (error) {
        message.error(`Error fetching image: ${error.message}`);
      }
    };

    fetchImageUrl();
  }, []);

  const handleChange = (info) => {
    setFileList(info.fileList);
  };

  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.warning('Please select a file to upload');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('file', fileList[0].originFileObj);

    try {
      const uploadResponse = await uploadImageToAPI(formData);

      if (uploadResponse && uploadResponse.success) {
        const imageUrl = uploadResponse.response[0];

        await updateAdvertisementPopup(advertisementId, imageUrl);

        const newAdvertisementData = await fetchAdvertisementUrl();
        setAdvertisementUrl(newAdvertisementData.advertismentUrl);

        message.success('Advertisement image updated successfully!');
      } else {
        message.error('Failed to upload image');
      }
    } catch (error) {
      message.error(`Error uploading image: ${error.message}`);
    } finally {
      setLoading(false);
      setFileList([]);
    }
  };

  return (
    <div style={{ padding: 24 }} className="text-center">
      <div className="heading-challenge">Advertisement Post</div>
      {advertisementUrl && (
        <div style={{ marginBottom: 16 }} className="align-item-center">
          <Image
            width={300}
            src={advertisementUrl}
            alt="Advertisement"
            style={{ marginBottom: 16, padding: 5 }}
          />
        </div>
      )}

      <Dragger
        name="file"
        multiple={false}
        fileList={fileList}
        onChange={handleChange}
        beforeUpload={() => false}
        onRemove={() => setFileList([])}
        className="mb-4 d-block "
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single upload.</p>
      </Dragger>

      <div className="text-center mt-3">
        <Button
          type="primary"
          className="border-0 col-6 fs-6 fw-bold"
          style={{ background: '#dc0272', color: 'white' }}
          onClick={handleUpload}
          loading={loading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default AdvertisementPost;
